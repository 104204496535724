import {Component, OnInit} from '@angular/core';
import {CardStoreService} from '../../shared/card-store.service';
import {Observable} from 'rxjs';
import {CardPatternObstacleConnection} from '../../shared/card-pattern-obstacle-connection';
import {ActivatedRoute} from '@angular/router';
import {DeckType} from '../../shared/card-deck';


@Component({
  selector: 'em-obstacle-connection',
  templateUrl: './obstacle-connection.component.html',
  styleUrls: ['./obstacle-connection.component.css']
})
/**
 * Shows obstacle information (patterns) and problem information (misfits)
 *
 * Component is used in the right menue and retrieves all information
 * needed for the retrival of the obstacle/problem information via
 * the routing parameters (ids of carddeck, source and target, type of carddeck).
 * This information is retrieved on the ngOnInit method and passed to the
 * CardStoreService. The Result is saved in the observable
 * cardPatternObstacleConnection$ which is used in the html template to
 * show and layout all information about the obstacle (pattern->pattern) or
 * problem (misfit->pattern).
 */
export class ObstacleConnectionComponent implements OnInit {

  cardPatternObstacleConnection$: Observable<CardPatternObstacleConnection>;

  constructor(
    private cs: CardStoreService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(
      queryParamMap => this.cardPatternObstacleConnection$ = this.cs.getObstacleConnection(
        Number(queryParamMap.get('carddeckid')),
        DeckType[queryParamMap.get('carddecktype')],
        Number(queryParamMap.get('sourceid')),
        Number(queryParamMap.get('targetid')))
    );
  }

}
