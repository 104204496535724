<mat-sidenav-container>
  <mat-sidenav #secondRightSidenav mode="over" [fixedInViewport]="true" [fixedTopGap]=1 position="end">
    <em-side-nav-content [name]="'patternConnection'"></em-side-nav-content>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="toolbarspacer"></div>
    <ng-container *ngIf="cardPattern">
      <div class="card_discussion_frontside">
      <div class="card_discussion_header">
        <table class="card_discussion_header">
          <tr>
            <td class="card_discussion_header_number">{{ cardPattern.cardNumber }}</td>
            <td class="card_discussion_header_title">{{ cardPattern.name }}</td>
            <td class="card_discussion_header_image">
              <img [src]="cardPattern.imageUrl" alt="Icon">
            </td>
          </tr>
        </table>
      </div>
      <div class="card_discussion_summary">
        {{ cardPattern.summary }}
      </div>
      </div>
      <div class="sidenav_container" [@onSideNavChangeRight]="sideNavStateRight ? 'close' : 'close'">
        <div class="patternListSmall" fxLayout="column" fxLayoutGap="10px" style="height: 100%;">
          <ng-container *ngFor="let cc of cardPattern.cardCells">
            <ng-container *ngIf="cc.cardNumber !== 0">
              <a (click)="secondRightSidenav.toggle()"
                 [routerLink]="[{outlets:{second:['pattern-connection']}}]"
                 [queryParams]="{
              carddeckid:cardPattern.cardDeckId,
              carddecktype:cardPattern.cardDeckType,
              sourceid:cc.sourceId,
              targetid:cc.targetId}">
                <div emRecommendedRelation
                     [sourceId]="cc.sourceId" [targetId]="cc.targetId" [cardDeckType]="cardPattern.cardDeckType">
                  <em-card-pattern-small [cardDeckId]="cc.cardDeckId" [cardDeckType]="cardPattern.cardDeckType"
                                         [cardPatternId]="cc.targetId"></em-card-pattern-small>
                </div>
              </a>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </mat-sidenav-content>
</mat-sidenav-container>
