import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
/**
 * Interceptor which provides for every https api call the api credentials
 */
export class TokenInterceptor implements HttpInterceptor {
  private uName = 'empamos_dev';
  private uPass = '759651269498421657846216579222148554871321';

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const newRequest = request.clone({
      url: request.url + '?uname=' + this.uName + '&upass=' + this.uPass
    });
    return next.handle(newRequest);
  }
}
