export enum BackgroundColor {
  GameDesign = '#0c84c9',
  ProblemDesign = '#ff4f19',
  Standard = '#ffffff',
  RecommendationExisting = '#04AF76',
  RecommendationExtension = '#ffcd00'
}

export enum FontColor {
  GameDesign = '#ffffff',
  ProblemDesign = '#ffffff',
  Standard = '#444444',
  RecommendationLow = '#444444',
  RecommendationHigh = '#ffffff'
}
