import {Component, OnInit} from '@angular/core';
import {animateText, onSideNavChangeRight} from '../../animations/animations';

@Component({
  selector: 'em-right-menu',
  templateUrl: './right-menu.component.html',
  styleUrls: ['./right-menu.component.css'],
  animations: [onSideNavChangeRight, animateText]
})
/**
 * Displays the right menu to display additional content.
 *
 * Is used e.g. for the display of detailed obstacle/problem data retrieved
 * for a pattern or a misfit if the user clicks on a card cell number in
 * the PatternCardComponent.
 */
export class RightMenuComponent implements OnInit {

  public sideNavStateRight = false;

  constructor() { }

  ngOnInit(): void {
  }

}
