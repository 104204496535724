import {Component, Input, OnInit} from '@angular/core';
import {CardPattern} from '../../shared/card-pattern';
import {MatSidenav} from '@angular/material/sidenav';

@Component({
  selector: 'em-pattern-body',
  templateUrl: './pattern-body.component.html',
  styleUrls: ['./pattern-body.component.css']
})
export class PatternBodyComponent implements OnInit {

  static readonly maxNumberOfCells = 22;

  @Input() cardPattern: CardPattern;
  @Input() rightSidenav: MatSidenav;

  currentPageNumber: number;
  maxPageNumber: number;
  maxNumberOfCells: number;

  constructor() { }

  ngOnInit(): void {
    this.currentPageNumber = 0;
    this.maxNumberOfCells = PatternBodyComponent.maxNumberOfCells;
    this.maxPageNumber = Math.floor(this.cardPattern.cardCells.length / PatternBodyComponent.maxNumberOfCells);
    // Reduce MaxPage-Number if the page is filled at maximum
    if (this.cardPattern.cardCells.length % PatternBodyComponent.maxNumberOfCells === 0) {
      if (this.maxPageNumber > 0) {
        this.maxPageNumber--;
      }
    }
  }

  switchCellNumberPage(){
    if (this.maxPageNumber > 0) {
      if (this.currentPageNumber < this.maxPageNumber) {
        this.currentPageNumber++;
      } else {
        this.currentPageNumber = 0;
      }
    }
  }

  determineCellNumber(cellNumber: number) {
    return cellNumber + (this.currentPageNumber * PatternBodyComponent.maxNumberOfCells);
  }

  showPageTurner(){
    return this.maxPageNumber > 0;
  }

}
