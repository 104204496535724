import { Pipe, PipeTransform } from '@angular/core';
import {CardPattern} from '../../shared/card-pattern';
import {GameDesign} from '../../design-recommender/shared/game-design';
import {GameDesignElement} from '../../design-recommender/shared/game-design-element';

@Pipe({
  name: 'editorCardFilter'
})
export class EditorCardFilterPipe implements PipeTransform {

  transform(
    value: CardPattern[],
    search: GameDesignElement[]
  ){
    if (value) {
      return [
        ...value.filter((item) => {
          return search.some((property) => search.includes(property));
        }),
      ];
    }
  }
}
