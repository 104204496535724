import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
  selector: 'em-form-messages',
  templateUrl: './form-messages.component.html',
  styleUrls: ['./form-messages.component.css']
})
/**
 * Delivers the FormMessages for input forms
 *
 * Is used e.g. in the login form (login.component.ts) and is
 * initialized with its AbstractControl (@Input) in the
 * login.component.html via property binding ([control]=...)
 *
 * Can be reused for all kind of forms.
 */
export class FormMessagesComponent implements OnInit {

  @Input() control: AbstractControl;
  @Input() controlName: string;

  private allMessages = {
    userName: {
      required: 'Ein User Name muss angegeben werden.'
    },
    userToken: {
      required: 'Ein User Token muss angegeben werden.'
    }
  };

  constructor() { }

  ngOnInit(): void {
  }

  errorsForControl(): string[] {
    const messages = this.allMessages[this.controlName];

    if (
      !this.control ||
      !this.control.errors ||
      !messages ||
      !this.control.dirty
    ) { return null; }

    return Object.keys(this.control.errors)
      .map(err => messages[err]);
  }

}
