import {Component} from '@angular/core';
import { onMainContentChange } from './animations/animations';
import {OidcSecurityService} from 'angular-auth-oidc-client';

@Component({
  selector: 'em-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [ onMainContentChange ]
})

export class AppComponent {
  name = 'Angular';
  constructor(
    private oidcSecurityService: OidcSecurityService) {  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit(): void {
    this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken }) => {
      // ...
    });
  }
}
