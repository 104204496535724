import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorComponent } from './editor/editor.component';
import { DefaultDecksComponent } from './default-decks/default-decks.component';
import { GraphViewerComponent } from './graph-viewer/graph-viewer.component';
import { GraphEditorRoutingModule } from './graph-editor-routing.module';
import {MatTabsModule} from '@angular/material/tabs';
import {DeckExplorerModule} from '../deck-explorer/deck-explorer.module';
import { PatternCardComponent } from './pattern-card/pattern-card.component';
import { PatternBodyComponent } from './pattern-body/pattern-body.component';
import {MatCardModule} from '@angular/material/card';
import {DesignRecommenderModule} from '../design-recommender/design-recommender.module';
import {MatDividerModule} from '@angular/material/divider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import { DefaultCardFilterPipe } from './shared/default-card-filter.pipe';
import {ReactiveFormsModule} from '@angular/forms';
import { DeckSortPipe } from './shared/deck-sort.pipe';
import { PatternAttributionComponent } from './pattern-attribution/pattern-attribution.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { EditorCardFilterPipe } from './shared/editor-card-filter.pipe';
import {MatMenuModule} from '@angular/material/menu';
import { SideNavContentComponent } from './side-nav-content/side-nav-content.component';
import {PatternMenuComponent} from './pattern-menu/pattern-menu.component';
import {PatternConnectionsComponent} from './pattern-connections/pattern-connections.component';
import { CardPatternSmallComponent } from './card-pattern-small/card-pattern-small.component';


@NgModule({
  declarations: [
    EditorComponent,
    DefaultDecksComponent,
    GraphViewerComponent,
    PatternCardComponent,
    PatternBodyComponent,
    DefaultCardFilterPipe,
    DeckSortPipe,
    PatternAttributionComponent,
    EditorCardFilterPipe,
    SideNavContentComponent,
    PatternMenuComponent,
    PatternConnectionsComponent,
    CardPatternSmallComponent
  ],
  exports: [
    GraphViewerComponent
  ],
  imports: [
    GraphEditorRoutingModule,
    CommonModule,
    MatTabsModule,
    DeckExplorerModule,
    MatCardModule,
    DesignRecommenderModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatIconModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatMenuModule
  ]
})
export class GraphEditorModule { }
