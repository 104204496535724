import {Pipe, PipeTransform} from '@angular/core';
import {CardDeck} from '../../shared/card-deck';
import {CardPattern} from '../../shared/card-pattern';

@Pipe({
  name: 'defaultCardFilter'
})
export class DefaultCardFilterPipe implements PipeTransform {

  transform(
    value: CardPattern[],
    search: string
  ){
    if (value) {
      return [
        ...value.filter((item) => {
          return (item.cardNumber.toString().includes(search) || item.name.toLowerCase().includes(search.toLowerCase()));
        }),
      ];
    }
  }
}
// properties.some((property) => regexp.test(item[property]))
//      const regexp = new RegExp(search, 'i');
//       const properties = Object.keys(value[6]);
