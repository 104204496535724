import {Component, Input, OnInit} from '@angular/core';
import {DeckType} from '../../shared/card-deck';
import {CardPattern} from '../../shared/card-pattern';
import {CardStoreService} from '../../shared/card-store.service';
import {Observable} from 'rxjs';
import {EditorComponent} from '../editor/editor.component';

@Component({
  selector: 'em-card-pattern-small',
  templateUrl: './card-pattern-small.component.html',
  styleUrls: ['./card-pattern-small.component.css']
})
export class CardPatternSmallComponent implements OnInit {

  @Input() cardDeckId: number;
  @Input() cardPatternId: number;
  @Input() cardDeckType: DeckType;
  cardPattern$: Observable<CardPattern>;

  constructor(
    private cs: CardStoreService,
    private editor: EditorComponent
  ) {
  }

  ngOnInit(): void {
    this.cardPattern$ = this.cs.getPatternCardWithCell(this.cardDeckId, this.cardPatternId, this.cardDeckType);
  }

}
