<mat-sidenav-container class="sidenav-container" *ngIf="userValidation$ | async as userValidation">
  <mat-sidenav #rightSidenav mode="over"  [fixedInViewport]="true" [fixedTopGap]=1 position="end">
    <em-right-menu></em-right-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="toolbarspacer">
      <!-- If attribution is selected show pattern cards with em-pattern-attribution component -->
      <ng-container *ngIf="this.attribution>0">
        <em-pattern-attribution [elementAttributionId]="this.attribution" [subAttributionId]="this.subAttribution" [rightSidenav]="rightSidenav" [cardDeck$]="this.cardDeck$"></em-pattern-attribution>
      </ng-container>
      <ng-container *ngIf="this.attribution==0 && this.subAttribution>0">
        <!-- Swap attribution and subAttribution if only subAttribution is set-->
        <em-pattern-attribution [elementAttributionId]="this.subAttribution" [subAttributionId]="this.attribution" [rightSidenav]="rightSidenav" [cardDeck$]="this.cardDeck$"></em-pattern-attribution>
      </ng-container>
      <!-- If no attribution selected show right here all pattern cards -->
      <ng-container *ngIf="this.attribution==0 && this.subAttribution==0">
        <ng-container *ngIf="cardDeck$ | async as cardDeck">
          <ng-container *ngFor="let cp of cardDeck.cardPatterns|deckSort: this.gameDesign: this.cardDeck.type: this.recommendedGameDesign: this.recommendationSorting">
            <em-pattern-card [rightSidenav]="rightSidenav" [cardPatternId]="cp.id" [cardDeckId]="cp.cardDeckId" [cardDeckType]="cardDeck.type"></em-pattern-card>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
