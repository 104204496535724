import {Component, Input, OnInit} from '@angular/core';
import {CardPattern} from '../../shared/card-pattern';
import {CardStoreService} from '../../shared/card-store.service';
import {Observable} from 'rxjs';
import {DeckType} from '../../shared/card-deck';
import {MatSidenav} from '@angular/material/sidenav';

@Component({
  selector: 'em-pattern-card',
  templateUrl: './pattern-card.component.html',
  styleUrls: ['./pattern-card.component.css']
})
/**
 * Shows the pattern or misfit card with all linkages.
 *
 * On ngOnInit method it uses the CardStoreService to retrieve
 * all pattern data including the linkage data for the cells
 * displayed on the card. A cell is an  object containing all
 * linkage data, e.g. id of source an target and type of carddeck.
 * Cell data is needed for the retrieval of the obstacle/problem
 * description (via ObstacleConnectionComponent) if the user clicks
 * on the cell number.
 *
 * Pattern data with cell data is stored in the observable cardPattern$
 * and is used in the html template to display the card.
 *
 * All parameters needed for the retrieval of the pattern data are
 * provided via the routing parameters (e.g. cardDeckId, cardPatternId,
 * cardDeckType).
 */
export class PatternCardComponent implements OnInit {
  @Input() cardDeckId: number;
  @Input() cardPatternId: number;
  @Input() cardDeckType: DeckType;
  @Input() rightSidenav: MatSidenav;

  cardPattern$: Observable<CardPattern>;

  constructor(private cs: CardStoreService) { }

  ngOnInit(): void {
    this.cardPattern$ = this.cs.getPatternCardWithCell(this.cardDeckId, this.cardPatternId, this.cardDeckType);
  }

}
