<ng-container *ngIf="cardPattern$ | async as cardPattern; else loading">
  <mat-card class="mat-elevation-z4">
    <div class="card_pattern_frontside">
      <table class="card_table">
        <tbody>
          <tr>
            <td class="card_cell_cardnumber" rowspan="2" emAddedGameElement [elementId]="cardPattern.id">{{ cardPattern.cardNumber }}</td>
            <td class="card_cell"></td>
            <td class="card_cell_direction">v</td>
            <td class="card_cell"></td>
            <td class="card_cell"><em-element-add [cardPattern]="cardPattern"></em-element-add></td>
          </tr>
          <tr>
            <td class="card_cell_pic" rowspan="4" colspan="3">
              <img class="img_card" [src]="cardPattern.imageUrl" alt="Icon" emAddedGameElement [elementId]="cardPattern.id">
            </td>
            <td class="card_cell"></td>
          </tr>
          <tr>
            <td class="card_cell_direction">></td>
            <td class="card_cell_direction"><</td>
          </tr>
          <tr>
            <td class="card_cell"></td>
            <td class="card_cell"></td>
          </tr>
          <tr>
            <td class="card_cell"></td>
            <td class="card_cell"></td>
          </tr>
          <tr>
            <td class="card_cell_title" rowspan="2" colspan="5" emAddedGameElement [elementId]="cardPattern.id">
              {{ cardPattern.name }}
            </td>
          </tr>
          <tr></tr>
        </tbody>
      </table>
      <em-pattern-body [cardPattern]="cardPattern" [rightSidenav]="rightSidenav"></em-pattern-body>
    </div>
  </mat-card>
</ng-container>
<ng-template #loading>
  <div style="color:#999999">Karte wird geladen...</div>
</ng-template>
