<ng-container *ngIf="cardDeck$ | async as cardDeck">
  <ng-container *ngIf="elementAttribution$ | async as elementAttribution">
    <ng-container *ngFor="let attributionCategory of elementAttribution.categories">
      <ng-container *ngIf="this.getAttributionCardPatterns(attributionCategory, cardDeck.cardPatterns).length>0">
        <div class="categoryArea">
          <div [ngClass]="{
            'categoryTitle': true,
            'categoryAuftrag': attributionCategory.title === 'Auftrag',
            'categoryAktion': attributionCategory.title === 'Aktion',
            'categoryWelt': attributionCategory.title === 'Welt',
            'categoryReaktion': attributionCategory.title === 'Reaktion'
          }">
            <h1>{{attributionCategory.title}}</h1>
          </div>
          <ng-container *ngIf="!subElementAttribution$">
            <ng-container *ngFor="let cp of this.getAttributionCardPatterns(attributionCategory, cardDeck.cardPatterns)| defaultCardFilter: searchForm.value.search|deckSort: this.gameDesign: this.cardDeck.type: this.recommendedGameDesign: this.recommendationSorting">
              <em-pattern-card [rightSidenav]="rightSidenav" [cardPatternId]="cp.id" [cardDeckId]="cp.cardDeckId" [cardDeckType]="cardDeck.type"></em-pattern-card>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="subElementAttribution$ | async as subElementAttribution">
            <ng-container *ngFor="let subAttributionCategory of subElementAttribution.categories">
              <ng-container *ngIf="this.isSubAttributionCategory(attributionCategory, subAttributionCategory, cardDeck.cardPatterns)">
                <div class="categorySubArea">
                  <div [ngClass]="{
                      'categorySubTitle': true,
                      'categoryAuftrag': attributionCategory.title === 'Auftrag',
                      'categoryAktion': attributionCategory.title === 'Aktion',
                      'categoryWelt': attributionCategory.title === 'Welt',
                      'categoryReaktion': attributionCategory.title === 'Reaktion'
                    }">
                    <h3>{{subAttributionCategory.title}}</h3>
                  </div>
                  <ng-container *ngFor="let cp of this.getSubAttributionCardPatterns(subAttributionCategory, attributionCategory, cardDeck.cardPatterns)| defaultCardFilter: searchForm.value.search|deckSort: this.gameDesign: this.cardDeck.type: this.recommendedGameDesign: this.recommendationSorting">
                    <ng-container *ngIf="cp.cardNumber<120">
                      <em-pattern-card [rightSidenav]="rightSidenav" [cardPatternId]="cp.id" [cardDeckId]="cp.cardDeckId" [cardDeckType]="cardDeck.type"></em-pattern-card>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
