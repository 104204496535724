import {Injectable} from '@angular/core';
import {GameDesign} from './game-design';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {RecommendedGameDesign} from './recommended-game-design';
import {CardDeck} from '../../shared/card-deck';
import {catchError, map, retry, switchMap} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {RecommendedGameDesignRaw} from './recommended-game-design-raw';
import {RecommendationGameDesignRequest} from './recommendation-game-design-request';
import {GameDesignFactory} from './game-design-factory';
import {CardStoreService} from '../../shared/card-store.service';
import {RecommendedGameDesignRelationScore} from './recommended-game-design-relation-score';
import {RecommendationType} from './recommendation-type.enum';
import {BackgroundColor, FontColor} from './recommendation-color.enum';

const API_ENDPOINT_RECOMMENDATION = '/ai/api/recommendation';


@Injectable({
  providedIn: 'root'
})
export class RecommendationService {

  private recommendationToggle = false;

  private recommendedGameDesign: RecommendedGameDesign;

  private gameDesign$: BehaviorSubject<GameDesign>;

  private bsRecommendedDesign$: BehaviorSubject<RecommendedGameDesign>;

  private bsRecommendationSorting$: BehaviorSubject<boolean>;

  // Make Extension default
  private recommendationTypeSave: RecommendationType = RecommendationType.EXTENSION;

  // Flag for the use with the deckSort-Pipe to sort or not to sort the cards according to the recommendation scores
  private recommendationSorting = false;

  constructor(
    private http: HttpClient,
    private cs: CardStoreService) {
      this.cs.getUserLoginListener().subscribe( b => {
        if (b === undefined) {
          this.recommendedGameDesign = undefined;
        }
      });
  }

  public static addAlphaForRecommendation(color: string, opacity: number): string {
    // coerce values so ti is between 0 and 1.
    const opac = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + opac.toString(16).toUpperCase();
  }

  public static determineFontColorForRecommendation(recommendationScore: number): FontColor {
    if (recommendationScore >= 0.8) {
      return FontColor.RecommendationHigh;
    }else{
      return FontColor.RecommendationLow;
    }
  }

  public static determineBackgroundColorForRecommendation(recommendationType: RecommendationType): BackgroundColor {
    switch (recommendationType){
      case RecommendationType.EXISTING: {
        return BackgroundColor.RecommendationExisting;
      }
      case RecommendationType.EXTENSION: {
        return BackgroundColor.RecommendationExtension;
      }
      default: {
        throw new Error('No BackgroundColor determinable for RecommendationType ' + recommendationType);
      }
    }
  }

  public setGameDesignBehaviorSubject(gameDesignSubject: BehaviorSubject<GameDesign>){
    if (!this.checkIfRecommendationRequestIsPossible()) {
      this.gameDesign$ = gameDesignSubject;
      this.gameDesign$.subscribe(gameDesign => {
          this.fetchRecommendation(gameDesign);
      });
    }else{
      throw new Error('Game Design Behavior Subject already set.');
    }
  }

  private fetchRecommendation(gameDesign: GameDesign) {
    this.cs.getCardDeck(gameDesign.cardDeckPatternId).pipe(
      switchMap(cardDeckPattern => {
        return this.cs.getCardDeck(gameDesign.cardDeckProblemId).pipe(
          map(cardDeckProblem => ({cardDeckPattern, cardDeckProblem}))
        );
      })
    ).subscribe(({cardDeckPattern, cardDeckProblem}) => {
      switch (this.recommendationTypeSave) {
        case RecommendationType.ALL: {
          this.getRecommendation(gameDesign, cardDeckPattern, cardDeckProblem).subscribe(b => {
            this.recommendedGameDesign = b;
            this.getRecommendationBehaviorSubject().next(this.recommendedGameDesign);
          });
          break;
        }
        case RecommendationType.EXISTING: {
          this.getRecommendationForPacking(gameDesign, cardDeckPattern, cardDeckProblem).subscribe(b => {
            this.recommendedGameDesign = b;
            this.getRecommendationBehaviorSubject().next(this.recommendedGameDesign);
          });
          break;
        }
        case RecommendationType.EXTENSION: {
          this.getRecommendationForExpansion(gameDesign, cardDeckPattern, cardDeckProblem).subscribe(b => {
            this.recommendedGameDesign = b;
            this.getRecommendationBehaviorSubject().next(this.recommendedGameDesign);
          });
          break;
        }
        default: {
          throw new Error('Unknown RecommendationType ' + this.recommendationTypeSave);
        }
      }
    });
  }

  public getRecommendationBehaviorSubject(): BehaviorSubject<RecommendedGameDesign> {
    if (this.checkIfRecommendationRequestIsPossible()) {
      if (this.bsRecommendedDesign$ === undefined) {
        this.bsRecommendedDesign$ = new BehaviorSubject<RecommendedGameDesign>(this.recommendedGameDesign);
      }
      return this.bsRecommendedDesign$;
    }

    throw new Error('No recommendation possible due to missing gameDesign or cardDeck.');
  }

  public getRecommendationSortingBehaviorSubject(): BehaviorSubject<boolean> {
    if (this.checkIfRecommendationRequestIsPossible()) {
      if (this.bsRecommendationSorting$ === undefined) {
        this.bsRecommendationSorting$ = new BehaviorSubject<boolean>(this.recommendationSorting);
      }
      return this.bsRecommendationSorting$;
    }

    throw new Error('No recommendation sorting possible due to missing gameDesign or cardDeck.');
  }

  public hasRecommendation() {
    return this.recommendedGameDesign !== undefined && this.recommendationToggle;
  }

  /**
   * Activates or deactivats the delivery of recommendation information via the the get methods
   */
  public toggleRecommendation(): boolean{
    this.recommendationToggle = !this.recommendationToggle;
    return this.recommendationToggle;
  }

  /**
   * Checks if the current recommendation has a recommendation relation for the given soruce and target ids
   * @param sourceId Id of the source element
   * @param targetId Id of the target element
   */
  public hasRecommendationForRelation(sourceId: number, targetId: number): boolean {
    if (this.hasRecommendation()) {
      for (const recomRelationScore of this.recommendedGameDesign.recommendedGameDesignRelationScores) {
        const recommendedGameDesignRelation = recomRelationScore.recommendedGameDesignRelation;
        if (recommendedGameDesignRelation.sourceId === sourceId && recommendedGameDesignRelation.targetId === targetId) {
          return true;
        }
      }

      for (const recomRelationScore of this.recommendedGameDesign.recommendedGameDesignProblemRelationScores) {
        const recommendedGameDesignProblemRelation  = recomRelationScore.recommendedGameDesignRelation;
        if (recommendedGameDesignProblemRelation.sourceId === sourceId && recommendedGameDesignProblemRelation.targetId === targetId) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Checks if the given element is part of a recommended relations as a source element
   * @param elementId Id of the element which is part of the recommendation as a source element
   */
  public hasRecommendationForElement(elementId: number): boolean {
    if (this.hasRecommendation()) {
      for (const recomRelationScore of this.recommendedGameDesign.recommendedGameDesignRelationScores) {
        const recommendedGameDesignRelation = recomRelationScore.recommendedGameDesignRelation;
        if (recommendedGameDesignRelation.sourceId === elementId) {
          return true;
        }
      }
    }
    return false;
  }

  public getRecommendedGameDesignRelationScore(sourceId: number, targetId: number): RecommendedGameDesignRelationScore | null {
    if (this.hasRecommendation()) {
      for (const recomRelationScore of this.recommendedGameDesign.recommendedGameDesignRelationScores) {
        const recommendedGameDesignRelation = recomRelationScore.recommendedGameDesignRelation;
        if (recommendedGameDesignRelation.sourceId === sourceId && recommendedGameDesignRelation.targetId === targetId) {
          return recomRelationScore;
        }
      }
      for (const recomRelationScore of this.recommendedGameDesign.recommendedGameDesignProblemRelationScores) {
        const recommendedGameDesignProblemRelation = recomRelationScore.recommendedGameDesignRelation;
        if (recommendedGameDesignProblemRelation.sourceId === sourceId && recommendedGameDesignProblemRelation.targetId === targetId) {
          return recomRelationScore;
        }
      }
    }
    return null;
  }

  public checkIfRecommendationRequestIsPossible(){
    return this.gameDesign$ !== undefined;
  }

  /**
   * Fetches a recommendation for new relations between existing elements according to the given gameDesign from the ai service
   *
   * @param gameDesign GameDesign object for which a recommendation shall be fetched
   * @param cardDeckPattern CardDeck object specifying all possible game design elements
   * @param cardDeckProblem CardDeck object sepecifying all possible game design misfits
   * @return Observable of a RecommendedGameDesign
   */
  public getRecommendationForPacking(
    gameDesign: GameDesign,
    cardDeckPattern: CardDeck,
    cardDeckProblem: CardDeck): Observable<RecommendedGameDesign> {
    const recommendationGameDesignRequest = GameDesignFactory.createRecommendationGameDesignRequest(cardDeckPattern, cardDeckProblem, gameDesign);
    const jsonGameDesign = this.transformRecommendationGameDesignRequestToJSON(recommendationGameDesignRequest);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    const options = {
      headers: httpHeaders
    };
    return this.http.post<RecommendedGameDesignRaw>(
      `${API_ENDPOINT_RECOMMENDATION}/add/relations/existing`, jsonGameDesign, options
    ).pipe(retry(1), map(b => GameDesignFactory.fromRecommendedGameDesignRaw(b)),
      catchError(this.errorHandler)
    );
  }

  /**
   * Fetches a recommendation for relations between existing AND new elements
   *
   * @param gameDesign GameDesign to fetch a recommendation for
   * @param cardDeckPattern CardDeck to fetch a recommendation for
   * @param cardDeckProblem CardDeck to fetch a recommendation for
   */
  getRecommendation(gameDesign: GameDesign, cardDeckPattern: CardDeck, cardDeckProblem: CardDeck) {
    const recommendationGameDesignRequest = GameDesignFactory.createRecommendationGameDesignRequest(cardDeckPattern, cardDeckProblem, gameDesign);
    const jsonGameDesign = this.transformRecommendationGameDesignRequestToJSON(recommendationGameDesignRequest);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    const options = {
      headers: httpHeaders
    };
    return this.http.post<RecommendedGameDesignRaw>(
      `${API_ENDPOINT_RECOMMENDATION}/add/relations`, jsonGameDesign, options
    ).pipe(retry(1), map(b => GameDesignFactory.fromRecommendedGameDesignRaw(b)),
      catchError(this.errorHandler)
    );
  }

  /**
   * Fetches a recommendation for  relations to new elements according to the given gameDesign from the ai service
   *
   * @param gameDesign GameDesign object for which a recommendation shall be fetched
   * @param cardDeckPattern CardDeck object specifying all possible game design elements
   * @param cardDeckProblem CardDeck object specifying all possible game design misfits
   * @return Observable of a RecommendedGameDesign
   */
  public getRecommendationForExpansion(
    gameDesign: GameDesign,
    cardDeckPattern: CardDeck,
    cardDeckProblem: CardDeck): Observable<RecommendedGameDesign> {
    const recommendationGameDesignRequest = GameDesignFactory.createRecommendationGameDesignRequest(cardDeckPattern, cardDeckProblem, gameDesign);
    const jsonGameDesign = this.transformRecommendationGameDesignRequestToJSON(recommendationGameDesignRequest);
    const httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json',
      'Cache-Control': 'no-cache'
    });
    const options = {
      headers: httpHeaders
    };
    return this.http.post<RecommendedGameDesignRaw>(
      `${API_ENDPOINT_RECOMMENDATION}/add/relations/new`, jsonGameDesign, options
    ).pipe(retry(1), map(b => GameDesignFactory.fromRecommendedGameDesignRaw(b)),
      catchError(this.errorHandler)
    );
  }

  /**
   * Fetches a recommendation for new elements according to the given gameDesign from the ai service
   *
   * @param gameDesign GameDesign object for which a recommendation shall be fetched
   * @param cardDeckPattern CardDeck object specifying all possible game design elements
   * @param cardDeckProblem CardDeck object specifying all possible game design misfits
   * @return Observable of a RecommendedGameDesign
   */
  public getRecommendationForNewElements(gameDesign: GameDesign, cardDeckPattern: CardDeck, cardDeckProblem: CardDeck): Observable<RecommendedGameDesign> {
    const recommendationGameDesignRequest = GameDesignFactory.createRecommendationGameDesignRequest(cardDeckPattern, cardDeckProblem, gameDesign);
    const jsonGameDesign = this.transformRecommendationGameDesignRequestToJSON(recommendationGameDesignRequest);
    const httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json',
      'Cache-Control': 'no-cache'
    });
    const options = {
      headers: httpHeaders
    };
    return this.http.post<RecommendedGameDesignRaw>(
      `${API_ENDPOINT_RECOMMENDATION}/add/elements`, jsonGameDesign, options
    ).pipe(retry(1), map(b => GameDesignFactory.fromRecommendedGameDesignRaw(b)),
      catchError(this.errorHandler)
    );
  }

  /**
   * Transforms a complex RecommendationGameDesignRequest object to a json string
   *
   * @param recommendationGameDesignRequest RecommendationGameDesignRequest object to be transformed
   * @return string Json string of the RecommendationGameDesignRequest
   */
  public transformRecommendationGameDesignRequestToJSON(recommendationGameDesignRequest: RecommendationGameDesignRequest): string {
    return JSON.stringify(recommendationGameDesignRequest);
  }

  private errorHandler(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(error);
  }

  /**
   * Triggers an update of the current recommendation based on the local available game design and card deck
   *
   * All observers who have subscribed to the behaviorSubject are informed afterwards
   */
  public updateRecommendation(recommendationType: RecommendationType, gameDesign: GameDesign) {
    this.recommendationTypeSave = recommendationType;
    this.cs.getCardDeck(gameDesign.cardDeckPatternId).subscribe(cardDeck => {
        this.fetchRecommendation(gameDesign);
      }
    );
  }

  toggleRecommendationSorting(): boolean {
    this.recommendationSorting = !this.recommendationSorting;
    this.getRecommendationSortingBehaviorSubject().next(this.recommendationSorting);
    return this.recommendationSorting;
  }
}
