<ng-container *ngIf="cardPattern$ | async as cardPattern; else loading">
  <div class="patternCardSmall">
    <div class="patternListItem">
      {{cardPattern.cardNumber}}
    </div>
    <div class="patternListItem">
      <img class="smallPatternIcon" src="{{cardPattern.imageUrl}}">
    </div>
    <div class="patternListItem">
      {{cardPattern.name}}
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <div class="patternCardSmall">
  Wird geladen...
  </div>
</ng-template>
