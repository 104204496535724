<table class="card_table">
  <tbody>
    <tr>
      <td class="card_cell_number_left">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(0)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
      <td class="card_cell_number_middle_with_border">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(1)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
      <td class="card_cell_number_middle_with_border">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(2)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
      <td class="card_cell_number_middle_with_border">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(3)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
      <td class="card_cell_number_right_with_border">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(4)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
    </tr>
    <tr>
      <td class="card_cell_number_left">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(5)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
      <td class="card_cell_number_middle_with_border">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(6)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
      <td class="card_cell_number_middle_with_border">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(7)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
      <td class="card_cell_number_middle_with_border">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(8)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
      <td class="card_cell_number_right_with_border">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(9)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
    </tr>
    <tr>
      <td class="card_cell_number_left">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(10)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
      <td class="card_cell_number_middle_with_border">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(11)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
      <td class="card_cell_number_middle_with_border">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(12)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
      <td class="card_cell_number_middle_with_border">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(13)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
      <td class="card_cell_number_right_with_border">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(14)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
    </tr>
    <tr>
      <td class="card_cell_direction"><</td>
      <td class="card_cell_number_middle_with_border">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(15)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
      <td class="card_cell_number_middle_with_border">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(16)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
      <td class="card_cell_number_middle_with_border">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(17)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
      <td class="card_cell_direction">></td>
    </tr>
    <tr>
      <td class="card_cell_number_left">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(18)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
      <td class="card_cell_number_middle_with_border">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(19)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
      <td class="card_cell_direction">v</td>
      <td class="card_cell_number_middle_with_border">
        <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(20)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
      </td>
      <td class="card_cell_number_right_with_border">
        <ng-container *ngIf="showPageTurner()">
          <a [routerLink]="['/carddeck', cardPattern.cardDeckId]" (click)="switchCellNumberPage()">
            <div class="cardCellNumber" emRecommendedRelations
                 [maxNumberOfCells]="maxNumberOfCells" [cardPattern]="cardPattern" [currentPageNumber]="currentPageNumber" [maxPageNumber]="maxPageNumber">
              <div class="cellNumberText">...</div>
            </div>
          </a>
        </ng-container>
        <ng-container *ngIf="!showPageTurner()">
          <em-pattern-cell [cardPattern]="cardPattern" [cellNumber]="determineCellNumber(21)"  [rightSidenav]="rightSidenav"></em-pattern-cell>
        </ng-container>
      </td>
    </tr>
  </tbody>
</table>
