import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CardPattern} from '../../shared/card-pattern';
import {GameDesignService} from './game-design.service';
import {RecommendationService} from './recommendation.service';
import {BackgroundColor, FontColor} from './recommendation-color.enum';
import {RecommendedGameDesignRelationScore} from './recommended-game-design-relation-score';

@Directive({
  selector: '[emRecommendedRelations]'
})
/**
 * This directive gives an aggregated signal about the recommendations and selected game
 * design elements on hidden pages. It shall be attributed to a container element
 */
export class RecommendedRelationsDirective implements OnChanges {

  // CardPattern for which the recommendation relations should be shown
  @Input() cardPattern: CardPattern;
  // Maximum number of pages available
  @Input() maxPageNumber: number;
  // Current page number after user selection
  @Input() currentPageNumber: number;
  // Maximum number of cells on a pattern card (e.g. 22)
  @Input() maxNumberOfCells: number;

  constructor(private gs: GameDesignService,
              private rs: RecommendationService,
              private el: ElementRef) {
    if (gs.hasGameDesign()){
      const bs$ = this.gs.getGameDesignBehaviorSubject();
      // Ensure that the changes in the game design are reflected in different styling
      bs$.subscribe( () => {
        this.updateGameDesignStyling();
      });
    }

    // Ensure that the changes in the recommendation are reflected in different styling
    if (rs.checkIfRecommendationRequestIsPossible()) {
      this.rs.getRecommendationBehaviorSubject().subscribe(() => {
        if (!this.updateRecommendationStyling()){
          // if no recommendation styling is available, this might be due to
          // maximum of in or outbound relations are met or recommendation
          // was toggled of
          this.updateGameDesignStyling();
        }
      });
    }
  }

  /**
   * Ensure that the cell styling is updated if cell pattern page is altered
   */
  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes ) {
      if (changes.hasOwnProperty(propName)) {
        if (propName === 'currentPageNumber') {
          if (this.gs.hasGameDesign()) {
            if (this.rs.checkIfRecommendationRequestIsPossible()) {
              if (!this.updateRecommendationStyling()) {
                this.updateGameDesignStyling();
              }
            }
          }
          break;
        }
      }
    }
  }

  private cellIsHidden(cellNumber: number) {
    const minCellNumberOnCurrentPage = this.currentPageNumber * this.maxNumberOfCells;
    const maxCellNumberOnCurrentPage = minCellNumberOnCurrentPage + this.maxNumberOfCells - 1;
    return cellNumber < minCellNumberOnCurrentPage || cellNumber > maxCellNumberOnCurrentPage;
  }

  private updateGameDesignStyling() {
    // Check if there exists a game design relation on the hidden pages
    if (this.checkIfGameDesignRelationIsSelected()) {
      this.doStyling(BackgroundColor.GameDesign, FontColor.GameDesign, 1);
    }else{
      this.doStyling(BackgroundColor.Standard, FontColor.Standard, 1);
    }
  }

  /**
   * Checks if there are selected game design relations on a hidden page
   */
  private checkIfGameDesignRelationIsSelected() {
    let hasSelectedGameDesignRelation = false;
    if (this.cardPattern !== undefined) {
      for (let cellNumber = 0; cellNumber < this.cardPattern.cardCells.length; cellNumber++) {
        if (this.cellIsHidden(cellNumber)) {
          if (this.gs.hasGameDesignRelation(
            this.cardPattern.cardCells[cellNumber].sourceId,
            this.cardPattern.cardCells[cellNumber].targetId)
            || this.gs.hasGameDesignProblemRelation(
              this.cardPattern.cardCells[cellNumber].sourceId,
              this.cardPattern.cardCells[cellNumber].targetId)
            || this.gs.hasGameDesignCauseRelation(
              this.cardPattern.cardCells[cellNumber].sourceId,
              this.cardPattern.cardCells[cellNumber].targetId
            )
          ) {
            hasSelectedGameDesignRelation = true;
            break;
          }
        }
      }
    }
    return hasSelectedGameDesignRelation;
  }

  private doStyling(backgroundColor: BackgroundColor, fontColor: FontColor, opacity: number) {
    this.el.nativeElement.style.backgroundColor = RecommendationService.addAlphaForRecommendation(backgroundColor, opacity);
    this.el.nativeElement.style.color = fontColor;
    this.el.nativeElement.title = opacity;
  }

  private updateRecommendationStyling(): boolean {
    const maximumRecommendationRelationScore = this.fetchMaximumRecommendationRelationScore();
    if (maximumRecommendationRelationScore !== undefined) {
      // Use the 0.05 threshold because the opacity/alpha calculation cannot deal with very small numbers
      if (maximumRecommendationRelationScore.recommendationScore > 0.05) {
        this.doStyling(
          RecommendationService.determineBackgroundColorForRecommendation(maximumRecommendationRelationScore.recommendationType),
          RecommendationService.determineFontColorForRecommendation(maximumRecommendationRelationScore.recommendationScore),
          maximumRecommendationRelationScore.recommendationScore
        );
        return true;
      }
    }
    this.doStyling(BackgroundColor.Standard, FontColor.Standard, 1);
    return false;
  }

  private fetchMaximumRecommendationRelationScore(): RecommendedGameDesignRelationScore | null{
    let maximumRecommendationRelationScore: RecommendedGameDesignRelationScore;
    if (this.cardPattern !== undefined) {
      for (let cellNumber = 0; cellNumber < this.cardPattern.cardCells.length; cellNumber++) {
        if (this.cellIsHidden(cellNumber)) {
          if (this.rs.hasRecommendationForRelation(
            this.cardPattern.cardCells[cellNumber].sourceId,
            this.cardPattern.cardCells[cellNumber].targetId)
          ) {
            const recommendationRelationScore = this.rs.getRecommendedGameDesignRelationScore(
              this.cardPattern.cardCells[cellNumber].sourceId,
              this.cardPattern.cardCells[cellNumber].targetId);
            if (maximumRecommendationRelationScore === undefined){
              maximumRecommendationRelationScore = recommendationRelationScore;
            }
            if (maximumRecommendationRelationScore.recommendationScore < recommendationRelationScore.recommendationScore) {
              maximumRecommendationRelationScore = recommendationRelationScore;
            }
          }
        }
      }
    }
    return maximumRecommendationRelationScore;
  }
}
