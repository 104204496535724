<ng-container *ngIf="cardPattern.cardCells[cellNumber].cardNumber > 0">
  <a (click)="rightSidenav.toggle()" [routerLink]="[
                {
                  outlets: {
                    second: [
                      'obstacle-connection'
                    ]
                  }
                }]" [queryParams]="{
                      carddeckid:cardPattern.cardDeckId,
                      carddecktype:cardPattern.cardDeckType,
                      sourceid:cardPattern.cardCells[cellNumber].sourceId,
                      targetid:cardPattern.cardCells[cellNumber].targetId}">
    <div class="cardCellNumber" emRecommendedRelation
         [sourceId]="cardPattern.cardCells[cellNumber].sourceId"
         [targetId]="cardPattern.cardCells[cellNumber].targetId"
         [cardDeckType]="cardPattern.cardDeckType">
      <div class="cellNumberText">{{ cardPattern.cardCells[cellNumber].cardNumber }}</div>
    </div>
  </a>
</ng-container>
