import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CardStoreService} from '../../shared/card-store.service';
import {Observable} from 'rxjs';
import {CardDeck, DeckType} from '../../shared/card-deck';
import {CardPatternObstacleConnection} from '../../shared/card-pattern-obstacle-connection';
import {CardPattern} from '../../shared/card-pattern';
import {RecommendedGameDesign} from '../shared/recommended-game-design';
import {ActivatedRoute} from '@angular/router';
import {GameDesignService} from '../shared/game-design.service';
import {RecommendationService} from '../shared/recommendation.service';
import {concatMap, retry} from 'rxjs/operators';

@Component({
  selector: 'em-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: ['./recommendation.component.css']
})
export class RecommendationComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private cs: CardStoreService,
    private route: ActivatedRoute,
    private ds: GameDesignService,
    private rs: RecommendationService) { }

  cardDeck$: Observable<CardDeck>;
  recommendedGameDesign$: Observable<RecommendedGameDesign>;

  cardPatterns = new Map<number, CardPattern>();
  possibleCardPatternObstacleConnections: CardPatternObstacleConnection[] = [];
  jsonString: string;

  gameDesignForm: FormGroup;
  recommendationForm: FormGroup;

  sourceSelected = false;
  isValidGameDesignFormSubmitted = false;
  isValidRecommendationFormSubmitted = false;

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      paramMap => this.cardDeck$ = this.cs.getCardDeck(Number(paramMap.get('carddeckid')))
    );
    this.initGameDesignForm();
    this.initRecommendationForm();
  }

  /**
   * Initializes and populates the game design form
   */
  private initGameDesignForm() {
    if (this.gameDesignForm) { return; }
    this.gameDesignForm = this.fb.group({
      source: [null, [ Validators.required ] ],
      target: [null, [ Validators.required ] ]
    });

    this.initCardDeck();
  }

  /**
   * Fetches the user validation and initializes the card deck
   */
  private initCardDeck(){
    this.cardDeck$.subscribe( b => {
        if (b.type === DeckType.pattern) {
          this.cardDeck$ = this.cs.getCardDeck(b.id);
          this.cardDeck$.subscribe(x => {
            this.ds.initGameDesign(b.id, b.type);
            for (const pattern of x.cardPatterns) {
              this.cardPatterns.set(pattern.id, pattern);
            }
          });
        }
    });
  }

  /**
   * Initializes and populates the recommendation form
   */
  private initRecommendationForm() {
    if (this.recommendationForm) { return; }

    this.recommendationForm = this.fb.group({
      recommendationRequest: [this.jsonString]
    });
  }

  /**
   * Adds a game design element to the game design
   */
  addGameDesignElement() {
    const formValue = this.gameDesignForm.value;
    this.ds.addGameDesignElement(formValue.source, true, this.getNameOfElement(formValue.source), DeckType.pattern);
  }

  /**
   * Adds an obstacle connection to the game design according to the values of the select form controls
   */
  addObstacleConnection() {
    this.isValidGameDesignFormSubmitted = false;
    if (this.gameDesignForm.valid) {
      this.isValidGameDesignFormSubmitted = true;
    } else {
      return;
    }

    const formValue = this.gameDesignForm.value;

    const sourceId = formValue.source;
    const sourceName = this.getNameOfElement(sourceId);
    const targetId = formValue.target;
    const targetName = this.getNameOfElement(targetId);
    this.ds.addGameDesignRelationItem(sourceId, sourceName, DeckType.pattern, targetId, targetName, DeckType.pattern, 0);
  }

  getNameOfElement(elementId: number): string | null {
    if (this.cardPatterns.has(elementId)) {
      return this.cardPatterns.get(elementId).name;
    }else{
      return null;
    }
  }

  /**
   * Removes a connection from the game design data structure according to the
   * selected elements in the select form controls
   */
  removeGameDesignRelation() {
    const formValue = this.gameDesignForm.value;
    this.ds.removeGameDesignRelationItem(formValue.source, formValue.target, 0, DeckType.pattern);
  }


  /**
   * Removes an element from the game design data structure according to the
   * selected elements in the select form control
   */
  removeGameDesignElement() {
    const formValue = this.gameDesignForm.value;
    this.ds.removeGameDesignElement(formValue.source);
  }

  /**
   * Submits the game design as a json object to retrieve recommendation data
   */
  submitGameDesignForRecommendationForPacking() {
    this.isValidRecommendationFormSubmitted = false;
    if (this.recommendationForm.valid) {
      this.isValidRecommendationFormSubmitted = true;
    } else {
      return;
    }

    this.recommendedGameDesign$ = this.cardDeck$.pipe(
      retry(1), concatMap(cardDeck => this.rs.getRecommendationForPacking(this.ds.getGameDesign(), cardDeck, cardDeck))
    );
  }

  /**
   * Submits the game design as a json object to retrieve recommendation data
   */
  submitGameDesignForRecommendationForExpansion() {
    this.isValidRecommendationFormSubmitted = false;
    if (this.recommendationForm.valid) {
      this.isValidRecommendationFormSubmitted = true;
    } else {
      return;
    }

    this.recommendedGameDesign$ = this.cardDeck$.pipe(
      // Double cardDeck because I was too lazy to fix this deprecated component recommenddation
      retry(1), concatMap(cardDeck => this.rs.getRecommendationForExpansion(this.ds.getGameDesign(), cardDeck, cardDeck))
    );
  }
  /**
   * Submits the game design as a json object to retrieve recommendation data
   */
  submitGameDesignForRecommendationForNewElements() {
    this.isValidRecommendationFormSubmitted = false;
    if (this.recommendationForm.valid) {
      this.isValidRecommendationFormSubmitted = true;
    } else {
      return;
    }

    this.recommendedGameDesign$ = this.cardDeck$.pipe(
      // Double cardDeck because I was too lazy to fix this deprecated component recommenddation
      retry(1), concatMap(cardDeck => this.rs.getRecommendationForNewElements(this.ds.getGameDesign(), cardDeck, cardDeck))
    );
  }

  /**
   * Submits the game design as a json object to retrieve recommendation data
   */
  submitGameDesignForRecommendation() {
    this.isValidRecommendationFormSubmitted = false;
    if (this.recommendationForm.valid) {
      this.isValidRecommendationFormSubmitted = true;
    } else {
      return;
    }

    this.recommendedGameDesign$ = this.cardDeck$.pipe(
      retry(1), concatMap(cardDeck => this.rs.getRecommendation(this.ds.getGameDesign(), cardDeck, cardDeck))
    );
  }

  /**
   * Function is called when a new source element is selected
   *
   * Is needed to fetch the possible obstacle connections and to populate the target select form
   * @param sourceId number Id of the selected source element
   */
  sourceElementSelected(sourceId: number) {
    this.sourceSelected = true;
    this.updatePossibleCardPatternObstacleConnections(sourceId);
  }

  /**
   * Updates the form control for the selection of possible target elements
   *
   * @param sourceId number Id of the selected source element
   */
  private updatePossibleCardPatternObstacleConnections(sourceId: number){
    this.cardDeck$.subscribe(b => {
      this.cs.getPatternCardWithCell(b.id, sourceId, b.type).subscribe(x => {
        this.possibleCardPatternObstacleConnections = [];
        for (const cardCell of x.cardCells){
          if (cardCell.targetId > 0) {
            this.cs.getObstacleConnection(b.id, b.type, x.id, cardCell.targetId).subscribe(z => {
              this.possibleCardPatternObstacleConnections.push(z);
            });
          }
        }
      });
    });
  }

  /**
   * Checks if an element is already part of the Game Design
   *
   * @param sourceId number Id of the element
   */
  elementIsPartOfGameDesignAsSource(sourceId: number) {
   return this.ds.elementIsPartOfGameDesignAsSource(sourceId);
  }

  /**
   * Checks if an element is already part of the Game Design
   *
   * @param targetId number Id of the element
   */
  elementIsPartOfGameDesignAsTarget(targetId: number) {
    return this.ds.elementIsPartOfGameDesignAsTarget(targetId);
  }



  clearGameDesign() {
    this.ds.clearGameDesign();
    this.recommendedGameDesign$ = null;
    this.recommendationForm.reset();
  }

  getGameDesign() {
    if (this.ds.hasGameDesign()) {
      return this.ds.getGameDesign();
    }
  }
}
