import {Component, Input, OnInit} from '@angular/core';
import {CardStoreService} from '../../shared/card-store.service';
import {RecommendationService} from '../shared/recommendation.service';
import {GameDesignService} from '../shared/game-design.service';
import {Location} from '@angular/common';
import {Observable} from 'rxjs';
import {UserValidation} from '../../shared/user-validation';
import {Router} from '@angular/router';
import {RecommendationType} from '../shared/recommendation-type.enum';

@Component({
  selector: 'em-recommendation-request',
  templateUrl: './recommendation-request.component.html',
  styleUrls: ['./recommendation-request.component.css']
})
export class RecommendationRequestComponent implements OnInit {

  @Input() userValidation$: Observable<UserValidation>;
  recommendationTypes = RecommendationType;

  private routePath = '';
  private enableColor: Map<RecommendationType, string> = new Map<RecommendationType, string>();
  private isEnabled: Map<RecommendationType, boolean> = new Map<RecommendationType, boolean>();

  constructor(private cs: CardStoreService,
              private rs: RecommendationService,
              private gs: GameDesignService,
              private router: Router,
              private location: Location) {
    this.cs.getUserLoginListener().subscribe( b => {
      this.isEnabled = new Map<RecommendationType, boolean>();
      this.enableColor = new Map<RecommendationType, string>();
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      if (this.location.path() !== '') {
        this.routePath = this.location.path();
      }else{
        this.routePath = 'Home';
      }
    });
  }

  public isOnActiveRoute(routeName: string): boolean {
    return this.routePath.includes(routeName);
  }

  public updateRecommendation(recommendationType: RecommendationType) {
    this.toggleRecommendation(recommendationType);
    this.rs.updateRecommendation(recommendationType, this.gs.getGameDesign());
  }

  public updateRecommendationSorting() {
    if (this.rs.toggleRecommendationSorting()) {
      this.enableElement(RecommendationType.SORTING);
    }else{
      this.disableElement(RecommendationType.SORTING);
    }
  }

  private getEnabledRecommendationType(): RecommendationType | null {
    let enabledRecommendationType = null;
    this.isEnabled.forEach((value: boolean, key: RecommendationType) => {
      if (value && key !== RecommendationType.SORTING) {
        enabledRecommendationType = key;
      }
    });
    return enabledRecommendationType;
  }

  private toggleRecommendation(recommendationType: RecommendationType) {
    const enabledRecommendationType = this.getEnabledRecommendationType();
    if (recommendationType !== enabledRecommendationType && enabledRecommendationType !== null) {
      this.disableElement(enabledRecommendationType);
      this.enableElement(recommendationType);
    }else {
      if (this.rs.toggleRecommendation()) {
        this.enableElement(recommendationType);
      }else {
        this.disableElement(recommendationType);
      }
    }
  }

  private enableElement(recommendationType: RecommendationType){
    this.enableColor.set(recommendationType, 'accent');
    this.isEnabled.set(recommendationType, true);
  }

  private disableElement(recommendationType: RecommendationType){
    this.enableColor.set(recommendationType, 'white');
    this.isEnabled.set(recommendationType, false);
  }

  getColor(recommendationType: RecommendationType): string {
    return this.enableColor.get(recommendationType);
  }

  canSort(): boolean {
    // const enabledRecommendationType = this.getEnabledRecommendationType();
    // return enabledRecommendationType !== null;
    return true;
  }
}
