<mat-sidenav-container *ngIf="userValidation$ | async as userValidation">
  <mat-sidenav #rightSidenav mode="over"  [fixedInViewport]="true" [fixedTopGap]=1 position="end">
    <em-side-nav-content [name]="'sideMenu'"></em-side-nav-content>
  </mat-sidenav>
  <mat-sidenav-content>
  <div class="flexGroup">

    <div class="cardSelection">
      <div class="toolbarspacer"></div>
      <div class="itemAligner">
        <form [formGroup]="searchForm">
          <input formControlName="search" placeholder="Suche nach Titel" />
        </form>
      <mat-tab-group mat-align-tabs="center" fitInkbarToContent animationDuration="250ms">
        <ng-container *ngFor="let cardDeck of userValidation.carddecks">
          <ng-container *ngIf="cardDeck.type === 'pattern'||cardDeck.type === 'causes'">
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon *ngIf="cardDeck.type === 'causes'">electric_bolt</mat-icon>
                <ng-container *ngIf="cardDeck.type === 'pattern'">
                  <mat-icon>hexagon</mat-icon>
                </ng-container>
              </ng-template>
              <em-default-decks [rightSidenav]="rightSidenav" [searchForm]="searchForm" [cardDeckIdSelection]="cardDeck.id" [deckType]="cardDeck.type"></em-default-decks>
            </mat-tab>
          </ng-container>
        </ng-container>
      </mat-tab-group>
    </div>
  </div>
    <div class="buildingCanvas">
      <div class="toolbarspacer"></div>
      <em-graph-viewer [rightSidenav]="rightSidenav"></em-graph-viewer>
    </div>
  </div>
  </mat-sidenav-content>
</mat-sidenav-container>
