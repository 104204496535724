import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {CarddeckComponent} from './carddeck/carddeck.component';
import {ObstacleConnectionComponent} from './obstacle-connection/obstacle-connection.component';
import {AutoLoginPartialRoutesGuard} from 'angular-auth-oidc-client';



const routes: Routes = [
  {
    path: ':carddeckid',
    component: CarddeckComponent,
    children: [
      {
        path: 'obstacle-connection',
        component: ObstacleConnectionComponent,
        outlet: 'second'
      }
    ],
    canActivate: [AutoLoginPartialRoutesGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeckExplorerRoutingModule { }
