import {NgModule } from '@angular/core';
import {AuthModule, LogLevel} from 'angular-auth-oidc-client';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        postLoginRoute: '/home',
        logLevel: LogLevel.Debug,
        historyCleanupOff: true,
        authority: 'https://auth.empamos.de/auth/realms/empamos',
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        clientId: 'ai-service',
        scope: 'openid microprofile-jwt profile email offline_access',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        renewTimeBeforeTokenExpiresInSeconds: 10,
        ignoreNonceAfterRefresh: true,
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}
