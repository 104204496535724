import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {GameDesignService} from './game-design.service';
import {RecommendationService} from './recommendation.service';
import {BackgroundColor, FontColor} from './recommendation-color.enum';
import {DeckType} from '../../shared/card-deck';

@Directive({
  selector: '[emRecommendedRelation]'
})
/**
 * This directive informs about the recommendation and game selection for a
 * particular game relation. It shall be attributed to a pattern cell.
 */
export class RecommendedRelationDirective implements OnChanges {

  @Input() sourceId: number;
  @Input() targetId: number;
  @Input() cardDeckType: DeckType;

  constructor(
    private gs: GameDesignService,
    private rs: RecommendationService,
    private el: ElementRef) {
    if (gs.hasGameDesign()){
      const bs$ = this.gs.getGameDesignBehaviorSubject();
      // Ensure that the changes in the game design are reflected in different styling
      bs$.subscribe( () => {
        this.updateGameDesignStyling();
      });
    }

    // Ensure that the changes in the recommendation are reflected in different styling
    if (rs.checkIfRecommendationRequestIsPossible()) {
      this.rs.getRecommendationBehaviorSubject().subscribe(() => {
        this.updateRecommendationStyling();
      });
    }
  }

  /**
   * Ensure that the cell styling is updated if cell pattern page is altered
   */
  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes ) {
      if (changes.hasOwnProperty(propName)) {
        if (propName === 'sourceId' || propName === 'targetId') {
          if (this.gs.hasGameDesign()) {
            this.updateGameDesignStyling();
          }
          if (this.rs.checkIfRecommendationRequestIsPossible()) {
            this.updateRecommendationStyling();
          }
          break;
        }
      }
    }
  }

  private updateGameDesignStyling() {
    if (this.gs.hasGameDesignRelation(this.sourceId, this.targetId)
      || this.gs.hasGameDesignProblemRelation(this.sourceId, this.targetId)
      || this.gs.hasGameDesignCauseRelation(this.sourceId, this.targetId)) {
      if (this.cardDeckType === DeckType.problem && this.gs.hasGameDesignProblem(this.sourceId)){
        this.doStyling(BackgroundColor.ProblemDesign, FontColor.ProblemDesign, 1);
      }else {
        this.doStyling(BackgroundColor.GameDesign, FontColor.GameDesign, 1);
      }
    }else{
      if (this.cardDeckType === DeckType.problem && this.gs.hasGameDesignPattern(this.targetId)){
        this.doStyling(BackgroundColor.GameDesign, FontColor.GameDesign, 1);
      }else {
        this.doStyling(BackgroundColor.Standard, FontColor.Standard, 1);
      }
    }
  }

  private updateRecommendationStyling(): boolean {
    if (!this.gs.hasGameDesignRelation(this.sourceId, this.targetId)
      && !this.gs.hasGameDesignProblemRelation(this.sourceId, this.targetId)
      && !this.gs.hasGameDesignCauseRelation(this.sourceId, this.targetId))
    {
      if (this.rs.hasRecommendationForRelation(this.sourceId, this.targetId)) {
        const recommendationRelationScore = this.rs.getRecommendedGameDesignRelationScore(this.sourceId, this.targetId);
        if (recommendationRelationScore.recommendationScore > 0.06) {
          this.doStyling(
            RecommendationService.determineBackgroundColorForRecommendation(recommendationRelationScore.recommendationType),
            RecommendationService.determineFontColorForRecommendation(recommendationRelationScore.recommendationScore),
            recommendationRelationScore.recommendationScore
          );
          return true;
        }
      }
      if (this.cardDeckType === DeckType.problem && this.gs.hasGameDesignPattern(this.targetId)) {
        if (this.gs.hasGameDesignProblemRelation(this.sourceId, this.targetId)) {
          this.doStyling(BackgroundColor.ProblemDesign, FontColor.ProblemDesign, 1);
        }else {
          this.doStyling(BackgroundColor.GameDesign, FontColor.GameDesign, 1);
        }
      } else {
        this.doStyling(BackgroundColor.Standard, FontColor.Standard, 1);
      }
    }
    return false;
  }

  private doStyling(backgroundColor: BackgroundColor, fontColor: FontColor, opacity: number) {
    this.el.nativeElement.style.backgroundColor = RecommendationService.addAlphaForRecommendation(backgroundColor, opacity);
    this.el.nativeElement.style.color = fontColor;
    if (backgroundColor !== BackgroundColor.Standard) {
      this.el.nativeElement.title = opacity;
    } else {
      this.el.nativeElement.title = '';
    }
  }

}

