import {CardDeck, DeckType} from '../../shared/card-deck';
import {GameDesignElement} from './game-design-element';
import {GameDesign} from './game-design';
import {GameDesignRelation} from './game-design-relation';
import {RecommendationGameDesignRequest} from './recommendation-game-design-request';
import {RecommendedGameDesignRelation} from './recommended-game-design-relation';
import {RecommendedGameDesignElement} from './recommended-game-design-element';
import {RecommendedGameDesignRelationScore} from './recommended-game-design-relation-score';
import {RecommendedGameDesignElementScore} from './recommended-game-design-element-score';
import {RecommendedGameDesign} from './recommended-game-design';
import {RecommendedGameDesignRaw} from './recommended-game-design-raw';
import {RecommendationType} from './recommendation-type.enum';

export class GameDesignFactory {

  static fromRecommendedGameDesignRaw(
    gameDesignRecommendationRaw: RecommendedGameDesignRaw): RecommendedGameDesign {

    // Create Game Design
    const newRecommendedGameDesign = this.emptyRecommendedGameDesign();

    // Add recommended Game Design Elements
    for ( const recommendedGameDesignElementRaw of gameDesignRecommendationRaw.recommendedGameDesignElements) {
      const newRecommendedGameDesignElementScore = this.emptyRecommendedGameDesignElementScore();
      newRecommendedGameDesignElementScore.recommendationScore = recommendedGameDesignElementRaw.recommendationScore;
      newRecommendedGameDesignElementScore.recommendationType = GameDesignFactory.createRecommendationType(
        recommendedGameDesignElementRaw.recommendationType
      );

      // Create Game Design Element
      const newRecommendedGameDesignElement = this.emptyRecommendedGameDesignElement();
      newRecommendedGameDesignElement.elementId = recommendedGameDesignElementRaw.id;
      newRecommendedGameDesignElement.name = recommendedGameDesignElementRaw.name;
      newRecommendedGameDesignElement.evidences = recommendedGameDesignElementRaw.evidences;
      newRecommendedGameDesignElementScore.recommendedGameDesignElement = newRecommendedGameDesignElement;
      newRecommendedGameDesign.recommendedGameDesignElementScores.push(newRecommendedGameDesignElementScore);
    }

    // Add recommended Game Design Relations
    for (const recommendedGameDesignRelationScoreRaw of gameDesignRecommendationRaw.recommendedGameDesignRelations) {
      // Create Game Design Relation Score
      const newRecommendedGameDesignRelationScore = this.emptyRecommendedGameDesignRelationScore();
      newRecommendedGameDesignRelationScore.recommendationScore = recommendedGameDesignRelationScoreRaw.recommendationScore;
      newRecommendedGameDesignRelationScore.recommendationType = GameDesignFactory.createRecommendationType(
        recommendedGameDesignRelationScoreRaw.recommendationType
      );

      // Create Game Design Relation
      const newRecommendedGameDesignRelation = this.emptyRecommendedGameDesignRelation();
      newRecommendedGameDesignRelation.sourceId = recommendedGameDesignRelationScoreRaw.sourceId;
      newRecommendedGameDesignRelation.targetId = recommendedGameDesignRelationScoreRaw.targetId;
      newRecommendedGameDesignRelation.weight = recommendedGameDesignRelationScoreRaw.weight;
      // Add Game Design Relation to Game Design Relation Score
      newRecommendedGameDesignRelationScore.recommendedGameDesignRelation = newRecommendedGameDesignRelation;
      // Add Game Design Relation Score to Game Design
      newRecommendedGameDesign.recommendedGameDesignRelationScores.push(newRecommendedGameDesignRelationScore);
    }

    // Add recommended Game Design Problems
    for ( const recommendedGameDesignElementRaw of gameDesignRecommendationRaw.recommendedGameDesignProblems) {
      const newRecommendedGameDesignElementScore = this.emptyRecommendedGameDesignElementScore();
      newRecommendedGameDesignElementScore.recommendationScore = recommendedGameDesignElementRaw.recommendationScore;
      newRecommendedGameDesignElementScore.recommendationType = GameDesignFactory.createRecommendationType(
        recommendedGameDesignElementRaw.recommendationType
      );

      // Create Game Design Problem
      const newRecommendedGameDesignElement = this.emptyRecommendedGameDesignElement();
      newRecommendedGameDesignElement.elementId = recommendedGameDesignElementRaw.id;
      newRecommendedGameDesignElement.name = recommendedGameDesignElementRaw.name;
      newRecommendedGameDesignElement.evidences = recommendedGameDesignElementRaw.evidences;
      newRecommendedGameDesignElementScore.recommendedGameDesignElement = newRecommendedGameDesignElement;
      newRecommendedGameDesign.recommendedGameDesignProblemScores.push(newRecommendedGameDesignElementScore);
    }

    // Add recommended Game Design Problem Relations
    for (const recommendedGameDesignRelationScoreRaw of gameDesignRecommendationRaw.recommendedGameDesignProblemRelations) {
      // Create Game Design Relation Score
      const newRecommendedGameDesignRelationScore = this.emptyRecommendedGameDesignRelationScore();
      newRecommendedGameDesignRelationScore.recommendationScore = recommendedGameDesignRelationScoreRaw.recommendationScore;
      newRecommendedGameDesignRelationScore.recommendationType = GameDesignFactory.createRecommendationType(
        recommendedGameDesignRelationScoreRaw.recommendationType
      );

      // Create Game Design Relation
      const newRecommendedGameDesignRelation = this.emptyRecommendedGameDesignRelation();
      newRecommendedGameDesignRelation.sourceId = recommendedGameDesignRelationScoreRaw.sourceId;
      newRecommendedGameDesignRelation.targetId = recommendedGameDesignRelationScoreRaw.targetId;
      newRecommendedGameDesignRelation.weight = recommendedGameDesignRelationScoreRaw.weight;
      // Add Game Design Relation to Game Design Relation Score
      newRecommendedGameDesignRelationScore.recommendedGameDesignRelation = newRecommendedGameDesignRelation;
      // Add Game Design Relation Score to Game Design
      newRecommendedGameDesign.recommendedGameDesignProblemRelationScores.push(newRecommendedGameDesignRelationScore);
    }

    return newRecommendedGameDesign;
  }

  private static createRecommendationType(recommendationType: string): RecommendationType {
    switch (recommendationType){
      case RecommendationType.EXISTING: {
        return RecommendationType.EXISTING;
      }
      case RecommendationType.EXTENSION: {
        return RecommendationType.EXTENSION;
      }
      default:
        throw new Error('Missing appropriate RecommendationType.');
    }
  }

  /**
   * Creates an empty game design relation which can be filled with values afterwards
   */
  public static emptyRecommendedGameDesignRelation(): RecommendedGameDesignRelation {
    return {
      sourceId: 0,
      targetId: 0,
      weight: 0
    };
  }

  /**
   * Creates an empty game design element object which can be filled with values afterwards
   */
  public static emptyRecommendedGameDesignElement(): RecommendedGameDesignElement {
    return {
      evidences: 0,
      name: '',
      elementId: 0
    };
  }

  /**
   * Creates an empty game design relation score object which can be filled with values afterwards
   */
  private static emptyRecommendedGameDesignRelationScore(): RecommendedGameDesignRelationScore {
    return {
      recommendedGameDesignRelation: undefined,
      recommendationScore: 0,
      recommendationType: undefined
    };
  }

  /**
   * Creates an empty CardDeck object which can be filled with values afterwards
   */
  private static emptyRecommendedGameDesignElementScore(): RecommendedGameDesignElementScore {
    return {
      recommendationType: undefined,
      recommendationScore: 0,
      recommendedGameDesignElement: undefined
    };
  }

  /**
   * Creates an empty CardDeck object which can be filled with values afterwards
   */
  private static emptyRecommendedGameDesign(): RecommendedGameDesign {
    return {
      recommendedGameDesignRelationScores: [],
      recommendedGameDesignElementScores: [],
      recommendedGameDesignProblemRelationScores: [],
      recommendedGameDesignProblemScores: []
    };
  }

  public static createGameDesignElement(id: number, isPositiveElement: boolean, name: string, deckType: DeckType): GameDesignElement {
    const gameDesignElement = this.emptyGameDesignElement();
    gameDesignElement.id = id;
    gameDesignElement.isPositiveElement = isPositiveElement;
    gameDesignElement.name = name;
    gameDesignElement.deckType = deckType;
    return gameDesignElement;
  }

  public static createGameDesign(cardDeckId: number, cardDeckType: DeckType) {
    const gameDesign = this.emptyGameDesign();
    switch (cardDeckType) {
      case DeckType.pattern:
        gameDesign.cardDeckPatternId = cardDeckId;
        break;
      case DeckType.problem:
      case DeckType.causes:
        gameDesign.cardDeckPatternId = cardDeckId;
        break;
      default:
        throw new Error('Cannot create game design due to unknown deckType ' + cardDeckType);
    }
    return gameDesign;
  }

  public static createGameDesignRelation(
    sourceId: number,
    sourceName: string,
    targetId: number,
    targetName: string,
    relationItemId: number): GameDesignRelation {

    const gameDesignRelation = this.emptyGameDesignRelation();
    gameDesignRelation.sourceId = sourceId;
    gameDesignRelation.targetId = targetId;
    gameDesignRelation.sourceName = sourceName;
    gameDesignRelation.targetName = targetName;
    gameDesignRelation.relationItemId = relationItemId;

    return gameDesignRelation;
  }

  static createRecommendationGameDesignRequest(cardDeckPattern: CardDeck, cardDeckProblem: CardDeck, gameDesign: GameDesign) {
    const recommendationGameDesignRequest = this.emptyRecommendationGameDesignRequest();
    recommendationGameDesignRequest.cardDeckPattern = cardDeckPattern;
    recommendationGameDesignRequest.cardDeckProblem = cardDeckProblem;
    recommendationGameDesignRequest.gameDesign = gameDesign;
    return recommendationGameDesignRequest;
  }


  /**
   * Creates an empty RecommendatinGameDesignRequest object which can be filled with values afterwards
   */
  static emptyRecommendationGameDesignRequest(): RecommendationGameDesignRequest {
    return {
      cardDeckProblem: undefined,
      cardDeckPattern: undefined,
      gameDesign: undefined
    };
  }

  /**
   * Creates an empty game design element which can be filled with values afterwards
   */
  private static emptyGameDesignElement(): GameDesignElement {
    return {
      isPositiveElement: true,
      id: 0,
      name: '',
      deckType: undefined,
    };
  }

  /**
   * Creates an empty game design element which can be filled with values afterwards
   */
  private static emptyGameDesignRelation(): GameDesignRelation {
    return {
      sourceId: 0,
      sourceName: '',
      targetId: 0,
      targetName: '',
      relationItemId: 0
    };
  }

  /**
   * Creates an empty game design which can be filled with values afterwards
   */
  private static emptyGameDesign(): GameDesign {
    return {
      cardDeckPatternId: 0,
      cardDeckProblemId: 0,
      gameDesignElements: [],
      gameDesignProblems: [],
      gameDesignRelations: [],
      gameDesignProblemRelations: [],
      gameDesignCauseRelations: []
    };
  }

}
