import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UserValidation} from '../../shared/user-validation';
import {Observable} from 'rxjs';
import {MatMenu} from '@angular/material/menu';

@Component({
  selector: 'em-pattern-body',
  templateUrl: './pattern-body.component.html',
  styleUrls: ['./pattern-body.component.css']
})
export class PatternBodyComponent implements OnInit {
  @ViewChild(MatMenu, {static: true}) menu: MatMenu;
  @Input() userValidation$: Observable<UserValidation>;

  constructor() {
  }

  ngOnInit(): void {
  }

}
