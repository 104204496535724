<mat-toolbar fxFlex="row" fxLayoutAlign="left center" fxLayoutGap="10px" class="header">
  <button mat-icon-button (click)="leftSideNav.toggle();">
    <mat-icon>menu</mat-icon>
  </button>
  <ng-container *ngIf="currentWindowWidth > 476">
    <span>EMPAMOS</span>
  </ng-container>
  <span class="spacer"></span>
  <em-attribution-request [userValidation$]="userValidation$"></em-attribution-request>
  <div class="mini_spacer"></div>
  <em-recommendation-request [userValidation$]="userValidation$"></em-recommendation-request>
  <div class="mini_spacer"></div>
  <em-help-menu [userValidation$]="userValidation$"></em-help-menu>
</mat-toolbar>
