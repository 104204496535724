import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {UserValidation} from '../../shared/user-validation';
import {CardStoreService} from '../../shared/card-store.service';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'em-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
/**
 * Displays the overall page for custom graphs, which consist of the available cards from default carddecks.
 * Split into graph-viewer to see, move and edit the custom graph.
 * And default-decks to show all available cards and being able to move them
 * into the custom graph.
 */
export class EditorComponent implements OnInit {

  userValidation$: Observable<UserValidation>;
  searchForm;
  rightSidenav;

  constructor(
    private cs: CardStoreService,
    private formBuilder: FormBuilder
  ) {
    this.searchForm = this.formBuilder.group({
      search: '',
    });
  }

  ngOnInit(): void {
    this.cs.getUserLoginListener().subscribe(b => this.userValidation$ = b);
  }
}
