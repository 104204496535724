import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {UserValidation} from '../../shared/user-validation';
import {AttributionType} from '../shared/attribution-types.enum';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AttributionService} from '../shared/attribution.service';
import {CardStoreService} from '../../shared/card-store.service';

@Component({
  selector: 'em-attribution-request',
  templateUrl: './attribution-request.component.html',
  styleUrls: ['./attribution-request.component.css']
})
export class AttributionRequestComponent implements OnInit {

  @Input() userValidation$: Observable<UserValidation>;
  attributionType = AttributionType;

  private routePath = '';
  private enableColor: Map<AttributionType, string> = new Map<AttributionType, string>();
  private isEnabled: Map<AttributionType, boolean> = new Map<AttributionType, boolean>();

  constructor(private router: Router,
              private location: Location,
              private as: AttributionService) {
  }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      if (this.location.path() !== '') {
        this.routePath = this.location.path();
      }else{
        this.routePath = 'Home';
      }
    });
  }

  public isOnActiveRoute(routeName: string): boolean {
    return this.routePath.includes(routeName);
  }

  public updateAttribution(attributedType: AttributionType) {
    this.toggleAttribution(attributedType);
    if (!this.as.toggleAttribution()) {
      attributedType = AttributionType.NO_ATTRIBUTION;
    }
    this.as.updateAttribution(attributedType);
  }

  public updateSubAttribution(attributedType: AttributionType) {
    this.toggleAttribution(attributedType);
    if (!this.as.toggleSubAttribution()) {
      attributedType = AttributionType.NO_ATTRIBUTION;
    }
    this.as.updateSubAttribution(attributedType);
  }

  private getEnabledAttributionType(attributedType: AttributionType): AttributionType | null {
    let enabledAttributionType = null;
    this.isEnabled.forEach((value: boolean, key: AttributionType) => {
      if (key === attributedType && value) {
        enabledAttributionType = key;
      }
    });
    return enabledAttributionType;
  }

  private toggleAttribution(attributedType: AttributionType) {
    const enabledAttributionType = this.getEnabledAttributionType(attributedType);
    if (enabledAttributionType === null) {
      this.enableElement(attributedType);
    }else {
      this.disableElement(attributedType);
    }
  }

  private enableElement(attributionType: AttributionType){
    this.enableColor.set(attributionType, 'accent');
    this.isEnabled.set(attributionType, true);
  }

  private disableElement(attributionType: AttributionType){
    this.enableColor.set(attributionType, 'white');
    this.isEnabled.set(attributionType, false);
  }

  getColor(attributionType: AttributionType): string {
    return this.enableColor.get(attributionType);
  }

}
