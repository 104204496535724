<div class="toolbarspacer">
  <div class="home_container full-height">
    <div class="mat-card mat-elevation-z3 landing_headline">
      <!--<h1><span style="font-weight:normal;color:#333;">Projekt</span> EMPAMOS</h1>-->
      <!--<h2><span class="subheadline_strong">Emp</span>irische <span class="subheadline_strong">A</span>nalyse <span class="subheadline_strong">mo</span>tivierender <span class="subheadline_strong">S</span>pielelemente</h2>-->
      <h1><span style="font-weight:normal;color:#333;"></span>Game Design<span style="font-weight:normal;color:#333;"><br/>Toolbox</span></h1>
      <h2>Spielerisch Motivation erzeugen</h2>
    </div>
    <div class="mat-card mat-elevation-z3 landing_text">
      <p>Hier finden Sie die digitalen Zusatzinhalte der Game Design Toolbox, die die Ergebnisse des Forschungsprojekts
      <a href="https://empamos.in.th-nuernberg.de/">EMPAMOS</a> enthält. Dieses Projekt führt die Technische
        Hochschule Nürnberg gemeinsam mit dem Deutschen Spielearchiv Nürnberg durch.</p>
      <p>Bitte beachten Sie, dass die Inhalte nur für die Teilnehmer*innen der Workshops zugänglich sind.</p>
      <p>Falls Sie selbst Interesse an diesen Inhalten haben, nehmen Sie gerne Kontakt mit uns auf: <a href="https://empamos.in.th-nuernberg.de/about/">Impressum</a></p>
      <ng-container *ngIf="userValidation$ | async as userValidation;else login">
        <p>Name: {{ userValidation.userName }}<br/>
        Organisation: {{ userValidation.userOrganisation }}</p>
        <p>Freigeschaltete Kartensets:
          <ng-container *ngFor="let cardDeck of userValidation.carddecks">
            <li>{{ cardDeck.name }}</li>
          </ng-container>
        </p>
      </ng-container>
      <ng-template #login>
        <em-login></em-login>
      </ng-template>
    </div>
  </div>
</div>
