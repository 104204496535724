<div class="toolbarspacer">
  <div class="home_container full-height">
    <div class="formContainer">
      <ng-container *ngIf="cardDeck$ | async as cardDeck">
        <h2> Create Game Design for CardDeck {{ cardDeck.name }} ({{ cardDeck.id }})</h2>
        <form [formGroup]="gameDesignForm">
          <p>
            <mat-form-field appearance="fill">
              <mat-label>Source</mat-label>
              <mat-select required formControlName="source" (selectionChange)="sourceElementSelected($event.value)">
                <mat-option *ngFor="let pattern of cardDeck.cardPatterns" [value]="pattern.id">
                  <ng-container *ngIf="elementIsPartOfGameDesignAsSource(pattern.id)">* </ng-container>
                  {{ pattern.name}} ({{ pattern.id }})
                </mat-option>
              </mat-select>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="fill">
              <mat-label>
                <ng-container *ngIf="possibleCardPatternObstacleConnections.length>0">
                  Target
                </ng-container>
                <ng-container *ngIf="possibleCardPatternObstacleConnections.length==0 && sourceSelected">
                  Loading
                </ng-container>
              </mat-label>
                <mat-select required formControlName="target">
                  <mat-option *ngFor="let availableObstacleConnection of possibleCardPatternObstacleConnections" [value]="availableObstacleConnection.obstaclePatternId">
                    <ng-container *ngIf="elementIsPartOfGameDesignAsTarget(availableObstacleConnection.obstaclePatternId)">* </ng-container>
                    {{ availableObstacleConnection.obstaclePatternName }} ({{ availableObstacleConnection.obstaclePatternId }})
                  </mat-option>
              </mat-select>
            </mat-form-field>
          </p>
          <p>
            <button mat-raised-button color="accent" (click)="addGameDesignElement()">
              Add Element
            </button>
            <button mat-raised-button color="accent" (click)="removeGameDesignElement()">
              Remove Element
            </button>
            <button mat-raised-button color="accent" (click)="addObstacleConnection()" [disabled]="gameDesignForm.invalid">
              Add Connection
            </button>
            <button mat-raised-button color="accent" (click)="removeGameDesignRelation()" [disabled]="gameDesignForm.invalid">
              Remove Connection
            </button>
          </p>
        </form>
      </ng-container>
      <h2>Fetch Recommendation for current Game Design</h2>
      <ng-container *ngIf="this.getGameDesign()">
        <h3>Added game design elements</h3>
        <ul *ngFor="let gameDesignElement of this.getGameDesign().gameDesignElements">
          <li *ngIf="gameDesignElement">
            {{gameDesignElement.name}} ({{gameDesignElement.id}})
          </li>
        </ul>
        <h3>Added game design relations</h3>
        <ng-container *ngFor="let gameDesignRelation of this.getGameDesign().gameDesignRelations">
          <p *ngIf="gameDesignRelation">
            {{gameDesignRelation.sourceName}} ({{gameDesignRelation.sourceId}}) -> {{gameDesignRelation.targetName}} ({{gameDesignRelation.targetId}})
          </p>
        </ng-container>
        <p>
          <button mat-raised-button color="accent" (click)="submitGameDesignForRecommendationForNewElements()" type="submit">
            New Elements
          </button>
          <button mat-raised-button color="accent" (click)="submitGameDesignForRecommendationForPacking()" type="submit">
            Packing
          </button>
          <button mat-raised-button color="accent" (click)="submitGameDesignForRecommendationForExpansion()" type="submit">
            Expanding
          </button>
          <button mat-raised-button color="accent" (click)="submitGameDesignForRecommendation()" type="submit">
            Merged
          </button>
          <button mat-raised-button color="accent" (click)="clearGameDesign()">
            Reset
          </button>
        </p>
      </ng-container>
      <h2>Recommendation</h2>
      <h3>Recommended Elements</h3>
      <ul *ngIf="this.recommendedGameDesign$ | async as recommendedGameDesign">
        <li *ngFor="let recomScore of recommendedGameDesign.recommendedGameDesignElementScores">
          {{recomScore.recommendationScore}}:
          {{this.getNameOfElement(recomScore.recommendedGameDesignElement.elementId)}}
          ({{recomScore.recommendedGameDesignElement.elementId}})
        </li>
      </ul>
      <h3>Recommended Relations</h3>
      <ul *ngIf="this.recommendedGameDesign$ | async as recommendedGameDesign">
        <li *ngFor="let recomScore of recommendedGameDesign.recommendedGameDesignRelationScores">
          {{recomScore.recommendationScore}}:
          {{this.getNameOfElement(recomScore.recommendedGameDesignRelation.sourceId)}}
          ({{recomScore.recommendedGameDesignRelation.sourceId}}) ->
          {{this.getNameOfElement(recomScore.recommendedGameDesignRelation.targetId)}}
          ({{recomScore.recommendedGameDesignRelation.targetId}}) [{{recomScore.recommendedGameDesignRelation.weight}}]
        </li>
      </ul>
    </div>
  </div>
</div>
