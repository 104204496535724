import {Component, HostListener, Input, OnInit} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {CardStoreService} from '../shared/card-store.service';
import {UserValidation} from '../shared/user-validation';
import {Observable} from 'rxjs';

@Component({
  selector: 'em-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
/**
 * Defines the common Header in the app with the central menu.
 */
export class HeaderComponent implements OnInit {
  @Input() leftSideNav: MatSidenav;
  userValidation$: Observable<UserValidation>;
  mobile: boolean;
   currentWindowWidth: number;

  constructor(private cs: CardStoreService) { }

  ngOnInit(): void {
    this.currentWindowWidth = window.innerWidth;
    this.cs.getUserLoginListener().subscribe(b => this.userValidation$ = b);
  }

  @HostListener('window:resize')
  onResize() {
    this.currentWindowWidth = window.innerWidth;
  }
}
