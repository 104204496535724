import {Directive, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';
import {GameDesignService} from './game-design.service';

@Directive({
  selector: '[emAddedGameElement]'
})
export class AddedGameElementDirective implements OnChanges{

  @Input() elementId: number;
  private isPartOfGameDesign = true;

  constructor(
    private gs: GameDesignService) {
    if (gs.hasGameDesign()){
      const bs$ = this.gs.getGameDesignBehaviorSubject();
      // Ensure that the changes in the game design are reflected in different styling
      bs$.subscribe( () => {
        this.isPartOfGameDesign = this.gs.hasGameDesignPattern(this.elementId) || this.gs.hasGameDesignProblem(this.elementId);
      });
    }
  }

  /**
   * Ensure that the cell styling is updated if cell pattern page is altered
   */
  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes ) {
      if (changes.hasOwnProperty(propName)) {
        if (propName === 'elementId') {
          if (this.gs.hasGameDesign()) {
            this.isPartOfGameDesign = this.gs.hasGameDesignPattern(this.elementId) || this.gs.hasGameDesignProblem(this.elementId);
          }
          break;
        }
      }
    }
  }

  @HostBinding('class') get elementClass(): string {
    if (!this.isPartOfGameDesign) {
      return 'nonactive_card';
    }
  }

}
