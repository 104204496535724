import {Injectable} from '@angular/core';
import {CardPattern} from '../../shared/card-pattern';
import {ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Provides child-data to parents, that are multiple layers above or other components,
 * that are not available within direct flow of data.
 * A ReplaySubject is used to be able to hold its current value,
 * subscribe to it and not be forced to insert an initial value
 */
export class EditorService {
  private patternSubject: ReplaySubject<CardPattern> = new ReplaySubject(1);

  constructor() {
  }

  public setConnectionParams(selectedPattern: CardPattern) {
    console.log(selectedPattern);
    this.patternSubject.next(selectedPattern);
  }

  public getConncectionParams() {
    return this.patternSubject.asObservable();
  }

}
