<ng-container *ngIf="userValidation$ | async as userValidation">
  <ng-container *ngIf="isOnActiveRoute('pattern') || isOnActiveRoute('causes')||isOnActiveRoute('editor')">
    <button matTooltip="Verbindungen zwischen selektierten Karten empfehlen" [matTooltipShowDelay]="300" mat-icon-button [color]="getColor(recommendationTypes.EXISTING)" class="menu_icon" (click)="updateRecommendation(recommendationTypes.EXISTING)">
      <mat-icon [inline]="true">multiple_stop</mat-icon>
    </button>
    <button matTooltip="Verbindungen zu neuen Karten empfehlen" [matTooltipShowDelay]="300" mat-icon-button [color]="getColor(recommendationTypes.EXTENSION)" class="menu_icon" (click)="updateRecommendation(recommendationTypes.EXTENSION)">
      <mat-icon [inline]="true">zoom_out_map</mat-icon>
    </button>
    <!--
    <button mat-icon-button [color]="getColor(recommendationTypes.ALL)" class="menu_icon" (click)="updateRecommendation(recommendationTypes.ALL)">
      <mat-icon [inline]="true">online_prediction</mat-icon>
    </button>-->
    <button matTooltip="Ausgewählte Karten sortieren" [matTooltipShowDelay]="300" mat-icon-button [color]="getColor(recommendationTypes.SORTING)" class="menu_icon" (click)="updateRecommendationSorting()" [disabled]="!canSort()">
      <mat-icon [inline]="true">sort</mat-icon>
    </button>
  </ng-container>
</ng-container>


