import { NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { TokenInterceptor } from './shared/token-interceptor.service';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCardModule} from '@angular/material/card';
import { HeaderComponent } from './header/header.component';
import {MatIconModule} from '@angular/material/icon';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormMessagesComponent } from './form-messages/form-messages.component';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {DeckExplorerModule} from './deck-explorer/deck-explorer.module';
import {DesignRecommenderModule} from './design-recommender/design-recommender.module';
import {AuthConfigModule} from './auth-config.module';
import {AuthInterceptor} from './shared/auth-interceptor';
import {MatTooltipModule} from '@angular/material/tooltip';
import { HelpMenuComponent } from './help-menu/help-menu.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatTabsModule} from '@angular/material/tabs';
import {GraphEditorModule} from './graph-editor/graph-editor.module';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    LeftMenuComponent,
    LoginComponent,
    FormMessagesComponent,
    HelpMenuComponent
  ],
    imports: [
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        MatToolbarModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatSidenavModule,
        MatListModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        DeckExplorerModule,
        DesignRecommenderModule,
        AuthConfigModule,
        MatTooltipModule,
        OverlayModule,
        MatTabsModule,
        GraphEditorModule

    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  exports: [ ],
  bootstrap: [AppComponent]
})
export class AppModule { }
