import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {CardDeck, DeckType} from './card-deck';
import {CardDeckRaw} from './card-deck-raw';
import {CardDeckFactory} from './card-deck-factory';
import {catchError, map, retry, shareReplay} from 'rxjs/operators';
import {CardPattern} from './card-pattern';
import {CardPatternRaw} from './card-pattern-raw';
import {CardPatternObstacleConnection} from './card-pattern-obstacle-connection';
import {CardPatternObstacleConnectionRaw} from './card-pattern-obstacle-connection-raw';
import {UserValidationRaw} from './user-validation-raw';
import {UserValidation} from './user-validation';
import {ElementAttribution} from './element-attribution';
import {ElementAttributionRaw} from './element-attribution-raw';

const CACHE_SIZE = 1;
const API_ENDPOINT_CMS = '/api/carddeck';

@Injectable({
  providedIn: 'root'
})
/**
 * Central Service which provides access to all relevant data objects
 */
export class CardStoreService {

  private cacheCardDeckMap: Map<number, Observable<CardDeck>> = new Map() ;
  private cachePatternCardMap: Map<number, Observable<CardPattern>> = new Map();
  private cacheUserValidation$: Observable<UserValidation>;

  private userLoginEvent: BehaviorSubject<Observable<UserValidation>>;
  private cacheElementAttributionCardMap: Map<number, Observable<ElementAttribution>> = new Map();

  constructor(private http: HttpClient) {
    this.userLoginEvent = new BehaviorSubject<Observable<UserValidation>>(this.cacheUserValidation$);
  }

  /**
   * Emits the UserLoginEvent when called
   *
   * Informs all subscribed parties that the current user has attempted to login
   *
   * @param userValidation$ UserValidation observable containing all user validation
   * data concering the current login of the user. Will be emitted to all subscribed
   * parties.
   */
  public emitUserLoginEvent(userValidation$: Observable<UserValidation>){
    this.userLoginEvent.next(userValidation$);
  }

  /**
   * Returns a UserLoginListener to which third parties can subscribe to
   *
   * Third parties subscribed to this listender will be informed
   * if a user has attempted to login.
   */
  public getUserLoginListener() {
    return this.userLoginEvent.asObservable();
  }

  /**
   * Clears the cachedUserValidation object within the CardStoreService
   *
   * Is needed in the case the user logs out.
   */
  public clearUserValidation(): void{
    this.cacheUserValidation$ = undefined;
    this.emitUserLoginEvent(this.cacheUserValidation$);
  }

  /**
   * Clears all cardDeck Data and cached Attribution Data
   *
   * Is needed to ensure that after logout no data is longer available
   * and that complete new data as to be fetched for each login
   */
  public clearUserData(): void{
    this.cacheCardDeckMap = new Map();
    this.cacheElementAttributionCardMap = new Map();
    this.cachePatternCardMap = new Map();
  }

  /**
   * Returns an observable for a carddeck identified by its id
   *
   * If cached the carddeck observable will be retrieved from cache.
   * Otherwise a http request is initiated to get the CardDeck.
   *
   * @param id Id of the CardDeck
   * @return Observable<CardDeck> Observable CardDeck
   */
  public getCardDeck(id: number): Observable<CardDeck>{
    if (!this.cacheCardDeckMap[id]) {
      this.cacheCardDeckMap[id] = this.requestCardDeck(id).pipe(
        shareReplay(CACHE_SIZE)
      );
    }
    return this.cacheCardDeckMap[id];
  }


  private requestCardDeck(id: number): Observable<CardDeck>{
    return this.http.get<CardDeckRaw>(
      `${API_ENDPOINT_CMS}/${id}`
    ).pipe(retry(1), map(b => CardDeckFactory.fromCardDeckRaw(b)),
      catchError(this.errorHandler)
    );
  }

  public getElementAttribution(cardDeckId: number, attributionId: number): Observable<ElementAttribution> {
    if (!this.cacheElementAttributionCardMap[attributionId]) {
      this.cacheElementAttributionCardMap[attributionId] = this.requestElementAttribution(cardDeckId, attributionId).pipe(
        shareReplay(CACHE_SIZE)
      );
    }
    return this.cacheElementAttributionCardMap[attributionId];
  }

  private requestElementAttribution(cardDeckId: number, attributionId: number): Observable<ElementAttribution> {
    return this.http.get<ElementAttributionRaw>(
      `${API_ENDPOINT_CMS}/${cardDeckId}/attribution/${attributionId}`
    ).pipe(retry(2), map(b => CardDeckFactory.fromElementAttributionRaw(b)),
      catchError(this.errorHandler)
    );
  }

  /**
   * Returns an observable CardPattern including all number cells
   *
   * If cached the CardPattern observable will be retrieved from cache.
   * Otherwise a http request is initiated to get the CardDeck.
   *
   * @param cardDeckId Id of the CardDeck
   * @param cardPatternId Id of the CardPattern within the CardDeck
   * @param cardDeckType Type of CardDeck
   */
  public getPatternCardWithCell(cardDeckId: number, cardPatternId: number, cardDeckType: DeckType): Observable<CardPattern>{
    if (!this.cachePatternCardMap[cardPatternId]) {
      this.cachePatternCardMap[cardPatternId] = this.requestPatternCardWithCell(cardDeckId, cardPatternId, cardDeckType).pipe(
        shareReplay(CACHE_SIZE)
      );
    }
    return this.cachePatternCardMap[cardPatternId];
  }



  private requestPatternCardWithCell(cardDeckId: number, cardPatternId: number, cardDeckType: DeckType): Observable<CardPattern>{
    let relationType = '';
    switch (cardDeckType){
      case DeckType.pattern:
        relationType = 'obstacles';
        break;
      case DeckType.problem:
        relationType = 'solutions';
        break;
      case DeckType.causes:
        relationType = 'causes';
        break;
      default:
        throw new Error('Unknown cardDeckType for requestPatternCardWithCell ' + cardDeckType);
    }
    return this.http.get<CardPatternRaw>(
      `${API_ENDPOINT_CMS}/${cardDeckId}/${cardPatternId}/${relationType}`
    ).pipe(retry(2), map(b => CardDeckFactory.fromCardPatternObstacleRaw(b)),
      catchError(this.errorHandler)
    );
  }

  /**
   * Returns an observable CardPatternObstacleConnection
   *
   * If cached the CardPatternObstacleConnection observable will be retrieved from cache.
   * Otherwise a http request is initiated to get the CardDeck.
   *
   * @param cardDeckId Id of the CardDeck the connection belongs to
   * @param cardDeckType Type of the CardDeck
   * @param cardPatternId Id of the CardPattern the connection origins from
   * @param targetPatternId Id of the CardPattern to which the connection leads
   */
  public getObstacleConnection(
    cardDeckId: number,
    cardDeckType: DeckType,
    cardPatternId: number,
    targetPatternId: number): Observable<CardPatternObstacleConnection>{
    let relationType = '';
    switch (cardDeckType){
      case DeckType.pattern:
        relationType = 'obstacles';
        break;
      case DeckType.problem:
        relationType = 'solutions';
        break;
      case DeckType.causes:
        relationType = 'causes';
        break;
      default:
        throw new Error('Unknown cardDeckType ' + cardDeckType);
    }

    return this.http.get<CardPatternObstacleConnectionRaw>(
      `${API_ENDPOINT_CMS}/${cardDeckId}/${cardPatternId}/${relationType}/${targetPatternId}`
    ).pipe(retry(2), map(b => CardDeckFactory.fromCardPatternObstacleConnectionRaw(b)),
      catchError(this.errorHandler)
    );
  }

  /**
   * Validates if the current user is a valid one according to its credentials
   *
   * Passes the userValidation data to the api rest endpoint of the validation system
   * (currently CMS) and creates an observable UserValidation object which informs
   * about the validation response.
   *
   * @param userValidation UserValidation object containing the user credentials
   * (at least userName and userToken)
   */
  public validateUser(userValidation: UserValidation): Observable<UserValidation> {
    this.cacheUserValidation$ = this.requestUserValidation(userValidation);
    return this.cacheUserValidation$;
  }

  private requestUserValidation(userValidation: UserValidation){
    return this.http.get<UserValidationRaw>(
      `${API_ENDPOINT_CMS}/user/${userValidation.userName}/${userValidation.userToken}`
    ).pipe(retry(1), map(b => CardDeckFactory.fromUserValidationRaw(b)),
      catchError(this.errorHandler)
    );
  }

  private errorHandler(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(error);
  }
}
