import {CardPattern} from './card-pattern';

/**
 * Allows the differentiation between various carddeck types
 *
 * Is necessary in order to use the same data structure and templates
 * for different carddeck types. Depending on the carddeck type other
 * rest api endpoints have to be addressed.
 */
export enum DeckType{
  pattern = 'pattern',
  problem = 'problem',
  causes = 'causes'
}

/**
 * Data structure for a CardDeck
 *
 * Consists of CardPatterns.
 */
export interface CardDeck {
  id: number;
  name: string;
  type: DeckType;
  cardPatterns?: CardPattern[];
}

