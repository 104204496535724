import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {GameDesignService} from '../shared/game-design.service';
import {CardPattern} from '../../shared/card-pattern';

@Component({
  selector: 'em-element-add',
  templateUrl: './element-add.component.html',
  styleUrls: ['./element-add.component.css']
})
export class ElementAddComponent implements OnInit {

  elementSelect = new FormControl(false);

  constructor( private gs: GameDesignService ) { }

  @Input() cardPattern: CardPattern;
  ngOnInit(): void {
    this.gs.getGameDesignBehaviorSubject().subscribe(() => {
        if (this.gs.hasGameDesignPattern(this.cardPattern.id) || this.gs.hasGameDesignProblem(this.cardPattern.id)) {
          if (!this.elementSelect.value) {
            this.elementSelect.setValue(true);
          }
        }else{
          if (this.elementSelect.value) {
            this.elementSelect.setValue(false);
          }
        }
    });
  }

  changeElementSelect() {
    if (this.elementSelect.value){
      this.gs.addGameDesignElement(this.cardPattern.id, true, this.cardPattern.name, this.cardPattern.cardDeckType);
    }else{
      this.gs.removeGameDesignElement(this.cardPattern.id);
    }
  }
}
