import {Component, OnInit} from '@angular/core';
import {animateText, onSideNavChangeRight} from '../../animations/animations';
import {CardPattern} from '../../shared/card-pattern';
import {EditorService} from '../shared/editor.service';
import {CardDeck} from '../../shared/card-deck';
import {Observable} from 'rxjs';
import {CardStoreService} from '../../shared/card-store.service';

@Component({
  selector: 'em-pattern-menu',
  templateUrl: './pattern-menu.component.html',
  styleUrls: ['./pattern-menu.component.css'],
  animations: [onSideNavChangeRight, animateText]
})
export class PatternMenuComponent implements OnInit {

  public sideNavStateRight = false;
  cardPattern: CardPattern;
  cardDeck: CardDeck;
  patternList$: Observable<CardPattern>[];

  constructor(
    private es: EditorService,
    private cs: CardStoreService
  ) {
  }

  ngOnInit(): void {
    this.es.getConncectionParams().subscribe(response => {
      this.cardPattern = response;
      this.cs.getCardDeck(this.cardPattern.cardDeckId).subscribe(deck => {
        this.cardDeck = deck;
      });
    });
  }

}
