import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {CardDeck} from '../../shared/card-deck';
import {CardStoreService} from '../../shared/card-store.service';
import {RecommendationService} from '../../design-recommender/shared/recommendation.service';
import {GameDesignService} from '../../design-recommender/shared/game-design.service';
import {ActivatedRoute} from '@angular/router';
import {AttributionService} from '../../deck-explorer/shared/attribution.service';
import {RecommendedGameDesign} from '../../design-recommender/shared/recommended-game-design';
import {GameDesign} from '../../design-recommender/shared/game-design';
import {UserValidation} from '../../shared/user-validation';
import {AttributionType} from '../../deck-explorer/shared/attribution-types.enum';

import {FormBuilder} from '@angular/forms';
import {MatSidenav} from '@angular/material/sidenav';

@Component({
  selector: 'em-graph-viewer',
  templateUrl: './graph-viewer.component.html',
  styleUrls: ['./graph-viewer.component.css']
})
export class GraphViewerComponent implements OnInit {
  cardDeck$: Observable<CardDeck>;

  @Input() cardDeckIdSelection: number;
  @Input() rightSidenav: MatSidenav;
  recommendedGameDesign: RecommendedGameDesign;
  gameDesign: GameDesign;

  userValidation$: Observable<UserValidation>;

  attribution: AttributionType;
  subAttribution: AttributionType;
  recommendationSorting: boolean;

  constructor(
    private cs: CardStoreService,
    private rs: RecommendationService,
    private gs: GameDesignService,
    private route: ActivatedRoute,
    private as: AttributionService,
  ) {
    this.cs.getUserLoginListener().subscribe(b => {
      if (b === undefined) {
        this.recommendedGameDesign = undefined;
        this.gameDesign = undefined;
        this.cardDeck$ = undefined;
        this.userValidation$ = undefined;
      }
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      paramMap => {
        let cardDeckId;
        if (this.cardDeckIdSelection) {
          cardDeckId = this.cardDeckIdSelection;
        } else {
          cardDeckId = Number(paramMap.get('carddeckid'));
        }
        this.cardDeck$ = this.cs.getCardDeck(cardDeckId);
        this.cardDeck$.subscribe(deck => {
          this.gs.initGameDesign(deck.id, deck.type);

          this.gameDesign = this.gs.getGameDesign();
          this.gs.getGameDesignBehaviorSubject().subscribe(gameDesign => {
            this.gameDesign = gameDesign;
          });

          // Changes in the game design should cause new recommendations
          if (!this.rs.checkIfRecommendationRequestIsPossible()) {
            this.rs.setGameDesignBehaviorSubject(this.gs.getGameDesignBehaviorSubject());
          }

          // Changes in the recommendation should change the recommended game design
          this.rs.getRecommendationBehaviorSubject().subscribe(recommendedGameDesign => {
            if (this.rs.hasRecommendation()) {
              this.recommendedGameDesign = recommendedGameDesign;
            } else {
              this.recommendedGameDesign = null;
            }
          });

          this.rs.getRecommendationSortingBehaviorSubject().subscribe(sort => {
            this.recommendationSorting = sort;
          });
        });
      }
    );
    this.as.getAttributionBehaviorSubject().subscribe(
      attributionType => {
        this.attribution = attributionType;
      }
    );
    this.as.getSubAttributionBehaviorSubject().subscribe(
      attributionType => {
        this.subAttribution = attributionType;
      }
    );
    this.cs.getUserLoginListener().subscribe(b => {
      this.userValidation$ = b;
    });
    // Ensure that the GameDesign is initizalized with ALL cardDecks
    // and not only with the one derived from the routing param
    this.userValidation$.subscribe(userValidation => {
      for (const cardDeck of userValidation.carddecks) {
        this.gs.initGameDesign(cardDeck.id, cardDeck.type);
      }
    });
  }

}
