import { NgModule } from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AutoLoginPartialRoutesGuard} from 'angular-auth-oidc-client';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'home',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'pattern',
    loadChildren: () => import('./deck-explorer/deck-explorer.module').then(
      mod => mod.DeckExplorerModule
    ),
    canActivate: [AutoLoginPartialRoutesGuard]
  },
  {
    path: 'causes',
    loadChildren: () => import('./deck-explorer/deck-explorer.module').then(
      mod => mod.DeckExplorerModule
    ),
    canActivate: [AutoLoginPartialRoutesGuard]
  },
  {
    path: 'editor',
    loadChildren: () => import('./graph-editor/graph-editor.module').then(
      mod => mod.GraphEditorModule
    ),
    canActivate: [AutoLoginPartialRoutesGuard]
  },
  {
    path: 'recommendation',
    loadChildren: () => import('./design-recommender/design-recommender-routing.module').then(
      mod => mod.DesignRecommenderRoutingModule
    ),
    canActivate: [AutoLoginPartialRoutesGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
