import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeckExplorerRoutingModule } from './deck-explorer-routing.module';
import {CarddeckComponent} from './carddeck/carddeck.component';
import {PatternCardComponent} from './pattern-card/pattern-card.component';
import {ObstacleConnectionComponent} from './obstacle-connection/obstacle-connection.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {RightMenuComponent} from './right-menu/right-menu.component';
import {MatCardModule} from '@angular/material/card';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DesignRecommenderModule} from '../design-recommender/design-recommender.module';
import { PatternCellComponent } from './pattern-cell/pattern-cell.component';
import { PatternBodyComponent } from './pattern-body/pattern-body.component';
import { DeckSortPipe } from './shared/deck-sort.pipe';
import { PatternAttributionComponent } from './pattern-attribution/pattern-attribution.component';
import {MatListModule} from '@angular/material/list';
import { AttributionRequestComponent } from './attribution-request/attribution-request.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';


@NgModule({
  declarations: [
    CarddeckComponent,
    PatternCardComponent,
    ObstacleConnectionComponent,
    RightMenuComponent,
    PatternCellComponent,
    PatternBodyComponent,
    DeckSortPipe,
    PatternAttributionComponent,
    AttributionRequestComponent
  ],
  imports: [
    CommonModule,
    DeckExplorerRoutingModule,
    MatSidenavModule,
    MatCardModule,
    FlexLayoutModule,
    DesignRecommenderModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule
  ],
  exports: [
    AttributionRequestComponent,
    CarddeckComponent
  ]
})
/**
 * Integrates all components needed for the depiction of pattern/misfit cards
 * and the exploration of their obstacle/solution connections
 */
export class DeckExplorerModule { }
