import {Component, Input, OnInit} from '@angular/core';
import {DeckType} from '../../shared/card-deck';
import {MatSidenav} from '@angular/material/sidenav';
import {Observable} from 'rxjs';
import {CardPattern} from '../../shared/card-pattern';
import {CardStoreService} from '../../shared/card-store.service';
import {EditorService} from '../shared/editor.service';

@Component({
  selector: 'em-pattern-card',
  templateUrl: './pattern-card.component.html',
  styleUrls: ['./pattern-card.component.css']
})
export class PatternCardComponent implements OnInit {
  @Input() cardDeckId: number;
  @Input() cardPatternId: number;
  @Input() cardDeckType: DeckType;
  @Input() rightSidenav: MatSidenav;

  cardPattern$: Observable<CardPattern>;

  constructor(
    private cs: CardStoreService,
    private es: EditorService
  ) {
  }

  ngOnInit(): void {
    this.cardPattern$ = this.cs.getPatternCardWithCell(this.cardDeckId, this.cardPatternId, this.cardDeckType);
  }

  public openSideMenu() {
    this.cardPattern$.subscribe(pattern => {
      this.es.setConnectionParams(pattern);
    });
    this.rightSidenav.toggle();
  }
}
