import {Component, Input, OnInit} from '@angular/core';
import {CardPattern} from '../../shared/card-pattern';
import {MatSidenav} from '@angular/material/sidenav';

@Component({
  selector: 'em-pattern-cell',
  templateUrl: './pattern-cell.component.html',
  styleUrls: ['./pattern-cell.component.css']
})
export class PatternCellComponent implements OnInit {

  @Input() rightSidenav: MatSidenav;
  @Input() cellNumber: number;
  @Input() cardPattern: CardPattern;

  constructor() { }

  ngOnInit(): void {
  }

}
