<div>
  <em-header [leftSideNav]="leftSidenav"></em-header>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #leftSidenav mode="over"  [fixedInViewport]="true" [fixedTopGap]=1>
      <em-left-menu></em-left-menu>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>





