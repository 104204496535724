import {CardDeckRaw} from './card-deck-raw';
import {CardDeck, DeckType} from './card-deck';
import {CardPattern} from './card-pattern';
import {CardPatternRaw} from './card-pattern-raw';
import {CardCell} from './card-cell';
import {CardPatternObstacleConnection} from './card-pattern-obstacle-connection';
import {CardPatternObstacleItem} from './card-pattern-obstacle-item';
import {CardPatternObstacleConnectionRaw} from './card-pattern-obstacle-connection-raw';
import {UserValidationRaw} from './user-validation-raw';
import {UserValidation} from './user-validation';
import {ElementAttributionRaw} from './element-attribution-raw';
import {ElementAttribution} from './element-attribution';
import {ElementAttributionType} from './element-attribution-type.enum';
import {ElementAttributionCategory} from './element-attribution-category';
import {ElementAttributionCategoryItem} from './element-attribution-category-item';
import {ElementAttributionCategoryType} from './element-attribution-category-type.enum';

/**
 * Builds all elementary data objects needed for the components
 *
 * Builds the data objects from raw data types which are directly
 * derived from the rest api responses.
 */
export class CardDeckFactory {


  static fromElementAttributionRaw(elementAttributionRaw: ElementAttributionRaw) {
    const elementAttribution = this.emptyElementAttribution();
    elementAttribution.id = elementAttributionRaw.attribution_id;
    elementAttribution.title = elementAttributionRaw.attribution_title;
    elementAttribution.summary = elementAttributionRaw.attribution_summary;
    elementAttribution.type = this.createElementAttributionType(elementAttributionRaw.attribution_type);

    for (const elementAttributionCategoryRaw of elementAttributionRaw.attribution_categories) {
      const elementAttributionCategory = this.emptyElementAttributionCategory();
      elementAttributionCategory.id = elementAttributionCategoryRaw.category_id;
      elementAttributionCategory.title = elementAttributionCategoryRaw.category_title;
      elementAttributionCategory.summary = elementAttributionCategoryRaw.category_summary;
      elementAttributionCategory.type = this.createElementAttributionCategoryType(elementAttributionCategoryRaw.category_type);

      for (const elementAttributionCategoryItemRaw of elementAttributionCategoryRaw.category_items) {
        const elementAttributionCategoryItem = this.emptyElementAttributionCategoryItem();
        elementAttributionCategoryItem.elementId = elementAttributionCategoryItemRaw.element_id;
        elementAttributionCategoryItem.summary = elementAttributionCategoryItemRaw.attribution_summary;
        if (elementAttributionCategory.type === ElementAttributionCategoryType.ITEM) {
          elementAttributionCategoryItem.value = elementAttributionCategoryItemRaw.attribution_value;
        }
        elementAttributionCategory.items.push(elementAttributionCategoryItem);
      }
      elementAttribution.categories.push(elementAttributionCategory);
    }

    return elementAttribution;
  }

  private static createElementAttributionCategoryType(attributionCategoryType: string): ElementAttributionCategoryType {
    switch (attributionCategoryType){
      case ElementAttributionCategoryType.ITEM: {
        return ElementAttributionCategoryType.ITEM;
      }
      case ElementAttributionCategoryType.TEXT: {
        return ElementAttributionCategoryType.TEXT;
      }
      default: {
        throw new Error('Unknown attributionCategoryType ' + attributionCategoryType);
      }
    }
  }

  private static createElementAttributionType(attributionType: string): ElementAttributionType {
    switch (attributionType){
      case ElementAttributionType.PATTERN: {
        return ElementAttributionType.PATTERN;
      }
      case ElementAttributionType.PROBLEM: {
        return ElementAttributionType.PROBLEM;
      }
      case ElementAttributionType.PATTERN_DOMAIN: {
        return ElementAttributionType.PATTERN_DOMAIN;
      }
      case ElementAttributionType.PROBLEM_PATTERN: {
        return ElementAttributionType.PROBLEM_PATTERN;
      }
      default: {
        throw new Error('Unknown attributionType ' + attributionType);
      }
    }
  }

  private static emptyElementAttributionCategory(): ElementAttributionCategory {
    return {
      id: 0,
      title: '',
      type: undefined,
      summary: '',
      items: []
    };
  }

  private static emptyElementAttributionCategoryItem(): ElementAttributionCategoryItem {
    return {
      elementId: 0,
      summary: '',
      value: 0
    };
  }

  /**
   * Builds a CardDeck from a CardDeckRaw
   *
   * Translates all raw rest api data into object data
   * for type CardDeck
   *
   * @param b CardDeckRaw as a result from the rest api response
   * @returns CardDeck
   */
  static fromCardDeckRaw(b: CardDeckRaw): CardDeck {
    const cardDeck = this.emptyCardDeck();

    cardDeck.id = b.carddeck_id;
    cardDeck.name = b.carddeck_name;
    cardDeck.type = DeckType[b.carddeck_type];

    for (const cardDeckPattern of b.carddeck_patterns){
      const cardPattern = this.emptyCardPattern();
      cardPattern.id = cardDeckPattern.pattern_id;
      cardPattern.cardDeckId = cardDeck.id;
      cardPattern.name = cardDeckPattern.pattern_name;
      cardPattern.cardNumber = cardDeckPattern.pattern_number;
      cardDeck.cardPatterns.push(cardPattern);
    }

    return cardDeck;
  }

  /**
   * Builds a CardPattern from a CardPatternRaw
   *
   * Translates the raw rest api responses into data entries for
   * a CardPattern object. Fills the CardPattern object with CardCell
   * objects for all obstacle/problem linkages.
   * @param cardPatternRaw CardPatternRaw object derived from the rest
   * api response
   */
  static fromCardPatternObstacleRaw(cardPatternRaw: CardPatternRaw): CardPattern {
    const cardPattern = this.emptyCardPattern();
    cardPattern.id = cardPatternRaw.pattern_id;
    cardPattern.name = cardPatternRaw.pattern_name;
    cardPattern.cardNumber = cardPatternRaw.pattern_number;
    cardPattern.imageUrl = cardPatternRaw.pattern_image_url;
    cardPattern.cardDeckId = cardPatternRaw.carddeck_id;
    cardPattern.cardDeckType = DeckType[cardPatternRaw.carddeck_type];
    cardPattern.summary = cardPatternRaw.pattern_summary;

    // Sort cardCells by number
    cardPatternRaw.pattern_obstacles.sort((a, b) => (a.obstacle_pattern_number > b.obstacle_pattern_number) ? 1 : -1);

    let maxPageNumber = 0;
    if (cardPatternRaw.pattern_obstacles.length <= 22) {
      maxPageNumber = 0;
    }else{
      if (cardPatternRaw.pattern_obstacles.length <= 43) {
        maxPageNumber = 1;
      }else{
        if (cardPatternRaw.pattern_obstacles.length <= 64) {
          maxPageNumber = 2;
        }else{
          if (cardPatternRaw.pattern_obstacles.length <= 85) {
            maxPageNumber = 3;
          }
        }
      }
    }

    /* let maxPageNumber = Math.floor(cardPatternRaw.pattern_obstacles.length / 22);
     Reduce maxPageNumber if page is filled at maximum
    if (cardPatternRaw.pattern_obstacles.length % 22 === 0) {
      if (maxPageNumber > 0) {
        maxPageNumber--;
      }
    }*/

    let filledCellNumber = 0;
    for (let pageNumber = 0; pageNumber <=  maxPageNumber; pageNumber++) {
      // Determine the numberOfCells to be filled on that page
      let numberOfCellsOnPage = cardPatternRaw.pattern_obstacles.length;
      let onlyOnePage = true;
      if (maxPageNumber !== 0) {
        onlyOnePage = false;
        if (pageNumber !== maxPageNumber) {
          numberOfCellsOnPage = 21;
        }else if (pageNumber === maxPageNumber) {
          numberOfCellsOnPage = cardPatternRaw.pattern_obstacles.length - (pageNumber * 21);
        }
      }
      // Create pages for that page
      for (let cellNumber = 1; cellNumber < 23; cellNumber++){
        const cardCell = this.createCardCell(cellNumber, cardPatternRaw, filledCellNumber, numberOfCellsOnPage, onlyOnePage);
        if (cardCell.cardNumber > 0) {
          filledCellNumber++;
        }
        cardPattern.cardCells.push(cardCell);
      }
    }

    return cardPattern;
  }

  private static createCardCell(
    cellNumber: number,
    cardPatternRaw: CardPatternRaw,
    filledCellNumber: number,
    numberOfCellsOnPage: number,
    onlyOnePage: boolean) {
    const cardCell = this.emptyCardCell();
    if (CardDeckFactory.determineCellToFillMaxNumbers(cellNumber, numberOfCellsOnPage, onlyOnePage)) {
      const patternObstacle = cardPatternRaw.pattern_obstacles[filledCellNumber];
      cardCell.cardNumber = patternObstacle.obstacle_pattern_number;
      cardCell.cardDeckId = patternObstacle.obstacle_carddeck_id;
      cardCell.sourceId = cardPatternRaw.pattern_id;
      cardCell.targetId = patternObstacle.obstacle_pattern_id;
    }
    return cardCell;
  }

  /**
   * Builds a UserValidation object from the raw response of the rest api
   *
   * Additionally to the userName an userOrganisation the userValidation
   * consists of the accessToken and the shallow CardDeck objects (without
   * CardPattern objects) for the unlocked carddecks
   *
   * @param userValidationRaw UserValidationRaw Derived from the rest api response
   */
  static fromUserValidationRaw(userValidationRaw: UserValidationRaw): UserValidation {
    const userValidation = this.emptyUserValidation();
    userValidation.userName = userValidationRaw.user_name;
    userValidation.accessToken = userValidationRaw.access_token;
    userValidation.userOrganisation = userValidationRaw.user_organisation;
    userValidation.userToken = userValidationRaw.user_token;
    for (const cardDeckRaw of userValidationRaw.carddecks) {
      const cardDeck = this.emptyCardDeck();
      cardDeck.id = cardDeckRaw.carddeck_id;
      cardDeck.name = cardDeckRaw.carddeck_name;
      cardDeck.type = DeckType[cardDeckRaw.carddeck_type];
      userValidation.carddecks.push(cardDeck);
    }

    return userValidation;
  }

  /**
   * Builds a CardPatternObstacleConnection from the api rest response
   *
   * Contains all data for a looked up obstacle connection including the
   * image url, the summary etc. Consists of multiple CardPatternObstacleItems
   * because between two patterns multiple Connections can exist.
   *
   * @param obstacleConnectionRaw CardPatternObstacleConnectionRaw Raw object
   * derived from the rest api response
   */
  static fromCardPatternObstacleConnectionRaw(obstacleConnectionRaw: CardPatternObstacleConnectionRaw): CardPatternObstacleConnection {
    const obstacleConnection = this.emptyCardPatternObstacleConnection();
    obstacleConnection.cardDeckId = obstacleConnectionRaw.carddeck_id;
    obstacleConnection.cardDeckName = obstacleConnectionRaw.cardeck_name;
    obstacleConnection.cardDeckType = obstacleConnectionRaw.carddeck_type;
    obstacleConnection.patternId = obstacleConnectionRaw.pattern_id;
    obstacleConnection.patternName = obstacleConnectionRaw.pattern_name;
    obstacleConnection.patternCardNumber = obstacleConnectionRaw.pattern_number;
    obstacleConnection.patternSummary = obstacleConnectionRaw.pattern_summary;
    obstacleConnection.obstaclePatternId = obstacleConnectionRaw.obstacle_pattern_id;
    switch (obstacleConnectionRaw.obstacle_pattern_type) {
      case DeckType.pattern:
        obstacleConnection.obstaclePatternType = DeckType.pattern;
        break;
      case DeckType.problem:
        obstacleConnection.obstaclePatternType = DeckType.problem;
        break;
      case DeckType.causes:
        obstacleConnection.obstaclePatternType = DeckType.causes;
        break;
      default:
        throw new Error('CMS delivered invalid obstacle_pattern_type: ' + obstacleConnectionRaw.obstacle_pattern_type);
    }
    obstacleConnection.obstaclePatternName = obstacleConnectionRaw.obstacle_pattern_name;
    obstacleConnection.patternImageUrl = obstacleConnectionRaw.pattern_image_url;

    for (const obstacleItemRaw of obstacleConnectionRaw.obstacles){
      const obstacleItem = this.emptyCardPatternObstacleItem();
      obstacleItem.id = obstacleItemRaw.obstacle_item_id;
      switch (obstacleItemRaw.obstacle_item_type) {
        case DeckType.pattern:
          obstacleItem.type = DeckType.pattern;
          break;
        case DeckType.problem:
          obstacleItem.type = DeckType.problem;
          break;
        case DeckType.causes:
          obstacleItem.type = DeckType.causes;
          break;
        default:
          throw new Error('CMS delivered invalid obstacle_item_type: ' + obstacleItemRaw.obstacle_item_type);
      }
      obstacleItem.cardNumber = obstacleItemRaw.obstacle_pattern_number;
      obstacleItem.question = obstacleItemRaw.obstacle_question;
      obstacleItem.description = obstacleItemRaw.obstacle_description;
      obstacleItem.solution = obstacleItemRaw.obstacle_solution;
      obstacleItem.patternDescription = obstacleItemRaw.obstacle_pattern_description;
      obstacleConnection.obstacleItems.push(obstacleItem);
    }

    return obstacleConnection;
  }

  /**
   * Creates an empty ElementAttribution object which can be filled with values afterwards
   */
  private static emptyElementAttribution(): ElementAttribution {
    return {
      id: 0,
      summary: '',
      title: '',
      type: undefined,
      categories: []
    };
  }

  /**
   * Creates an empty CardDeck object which can be filled with values afterwards
   */
  static emptyCardDeck(): CardDeck {
    return {
      id: 0,
      name: '',
      type: undefined,
      cardPatterns: []
    };
  }

  /**
   * Creates an empty CardPattern object which can be filled with values afterwards
   */
  static emptyCardPattern(): CardPattern {
    return {
      id: 0,
      cardDeckId: 0,
      cardDeckType: undefined,
      cardNumber: 0,
      name: '',
      imageUrl: '',
      summary: '',
      cardCells: []
    };
  }

  /**
   * Creates an empty UserValidation object which can be filled with values afterwards
   */
  static emptyUserValidation(): UserValidation {
    return {
      userName: '',
      userOrganisation: '',
      userToken: 0,
      accessToken: 0,
      carddecks: []
    };
  }

  /**
   * Creates an empty CardPatternObstacleConnection which can be filled with values afterwards
   */
  static emptyCardPatternObstacleConnection(): CardPatternObstacleConnection {
    return {
      cardDeckId: 0,
      cardDeckName: '',
      cardDeckType: undefined,
      patternId: 0,
      patternName: '',
      patternCardNumber: 0,
      patternSummary: '',
      patternImageUrl: '',
      obstaclePatternId: 0,
      obstaclePatternType: undefined,
      obstaclePatternName: '',
      obstacleItems: []
    };
  }

  /**
   * Creates an empty CardPatternObstacleItem which values can be filled afterwards
   */
  static emptyCardPatternObstacleItem(): CardPatternObstacleItem {
    return {
      id: 0,
      type: undefined,
      cardNumber: 0,
      question: '',
      description: 0,
      solution: '',
      patternDescription: ''
    };
  }

  /**
   * Creates an empty CardCell object which values can be filled afterwards
   */
  static emptyCardCell(): CardCell {
    return {
      cardNumber: 0,
      cardDeckId: 0,
      targetId: 0,
      sourceId: 0,
      obstacleConnection: undefined
    };
  }

  /**
   * Determines if a given CardCell objects has to used for displaying an obstacle connection
   *
   * Implements an algorithm which ensures that the numbers are distributed
   * evenly on the card.
   *
   * @param cellNumber Current number of the cell on the card (e.g. between 1 und 22)
   * @param totalNumber Total number of linkages to be displayed on the card
   * @param onlyOnePage Specifies if a pattern link pagination is needed
   * @returns boolean True if the cell (identified through its number) should be filled
   */
  private static determineCellToFillMaxNumbers(cellNumber: number, totalNumber: number, onlyOnePage: boolean): boolean{
    let cellToFill = false;
    switch (totalNumber){
      case 1:
        switch (cellNumber){
          case 8:
            cellToFill = true;
            break;
        }
        break;
      case 2:
        switch (cellNumber){
          case 7:
          case 9:
            cellToFill = true;
            break;
        }
        break;
      case 3:
        switch (cellNumber){
          case 2:
          case 4:
          case 13:
            cellToFill = true;
            break;
        }
        break;
      case 4:
        switch (cellNumber){
          case 2:
          case 4:
          case 12:
          case 14:
            cellToFill = true;
            break;
        }
        break;
      case 5:
        switch (cellNumber){
          case 2:
          case 4:
          case 8:
          case 12:
          case 14:
            cellToFill = true;
            break;
        }
        break;
      case 6:
        switch (cellNumber){
          case 2:
          case 4:
          case 8:
          case 12:
          case 14:
          case 17:
            cellToFill = true;
            break;
        }
        break;
      case 7:
        switch (cellNumber){
          case 2:
          case 4:
          case 6:
          case 8:
          case 10:
          case 12:
          case 14:
            cellToFill = true;
            break;
        }
        break;
      case 8:
        switch (cellNumber){
          case 2:
          case 4:
          case 6:
          case 8:
          case 10:
          case 12:
          case 14:
          case 17:
            cellToFill = true;
            break;
        }
        break;
      case 9:
        switch (cellNumber){
          case 1:
          case 3:
          case 5:
          case 7:
          case 9:
          case 11:
          case 15:
          case 16:
          case 18:
            cellToFill = true;
            break;
        }
        break;
      case 10:
        switch (cellNumber){
          case 1:
          case 3:
          case 5:
          case 7:
          case 9:
          case 11:
          case 13:
          case 15:
          case 16:
          case 18:
            cellToFill = true;
            break;
        }
        break;
      case 11:
        switch (cellNumber){
          case 1:
          case 3:
          case 5:
          case 7:
          case 9:
          case 11:
          case 15:
          case 16:
          case 18:
          case 19:
          case 22:
            cellToFill = true;
            break;
        }
        break;
      case 12:
        switch (cellNumber){
          case 1:
          case 3:
          case 5:
          case 7:
          case 9:
          case 11:
          case 13:
          case 15:
          case 16:
          case 18:
          case 19:
          case 22:
            cellToFill = true;
            break;
        }
        break;
      case 13:
        switch (cellNumber){
          case 2:
          case 4:
          case 6:
          case 8:
          case 10:
          case 12:
          case 13:
          case 14:
          case 17:
          case 19:
          case 20:
          case 21:
          case 22:
            cellToFill = true;
            break;
        }
        break;
      case 14:
        switch (cellNumber){
          case 1:
          case 2:
          case 4:
          case 5:
          case 6:
          case 8:
          case 10:
          case 12:
          case 14:
          case 17:
          case 19:
          case 20:
          case 21:
          case 22:
            cellToFill = true;
            break;
        }
        break;
      case 15:
        switch (cellNumber){
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 8:
          case 10:
          case 12:
          case 14:
          case 17:
          case 19:
          case 20:
          case 21:
          case 22:
            cellToFill = true;
            break;
        }
        break;
      case 16:
        switch (cellNumber){
          case 1:
          case 2:
          case 4:
          case 5:
          case 6:
          case 8:
          case 10:
          case 11:
          case 12:
          case 14:
          case 15:
          case 17:
          case 19:
          case 20:
          case 21:
          case 22:
            cellToFill = true;
            break;
        }
        break;
      case 17:
        switch (cellNumber){
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 8:
          case 10:
          case 11:
          case 12:
          case 14:
          case 15:
          case 17:
          case 19:
          case 20:
          case 21:
          case 22:
            cellToFill = true;
            break;
        }
        break;
      case 18:
        switch (cellNumber){
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 8:
          case 10:
          case 11:
          case 12:
          case 13:
          case 14:
          case 15:
          case 17:
          case 19:
          case 20:
          case 21:
          case 22:
            cellToFill = true;
            break;
        }
        break;
      case 19:
        switch (cellNumber){
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 8:
          case 10:
          case 11:
          case 12:
          case 13:
          case 14:
          case 15:
          case 16:
          case 18:
          case 19:
          case 20:
          case 21:
          case 22:
            cellToFill = true;
            break;
        }
        break;
      case 20:
        switch (cellNumber){
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
          case 10:
          case 11:
          case 13:
          case 15:
          case 16:
          case 17:
          case 18:
          case 19:
          case 20:
          case 21:
          case 22:
            cellToFill = true;
            break;
        }
        break;
      case 21:
        switch (cellNumber){
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
          case 10:
          case 11:
          case 12:
            cellToFill = true;
            break;
          case 13:
            cellToFill = !onlyOnePage;
            break;
          case 14:
          case 15:
          case 16:
          case 17:
          case 18:
          case 19:
          case 20:
          case 21:
            cellToFill = true;
            break;
          case 22:
            cellToFill = onlyOnePage;
            break;
        }
        break;
      case 22:
        cellToFill = true;
        break;
      default:
    }
    return cellToFill;
  }
}
