import {Injectable} from '@angular/core';
import {AttributionType} from './attribution-types.enum';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AttributionService {
  private attributionToggle = false;
  private subAttributionToggle = false;

  private bsAttribution$: BehaviorSubject<AttributionType>;
  private bsSubAttribution$: BehaviorSubject<AttributionType>;

  // Make default decision
  private attributionTypeSave: AttributionType = AttributionType.NO_ATTRIBUTION;

  // Make default decision
  private subAttributionTypeSave: AttributionType = AttributionType.NO_ATTRIBUTION;

  constructor() {
  }

  public getAttributionBehaviorSubject(): BehaviorSubject<AttributionType> {
    if (this.bsAttribution$ === undefined) {
      this.bsAttribution$ = new BehaviorSubject<AttributionType>(this.attributionTypeSave);
    }
    return this.bsAttribution$;
  }

  public getSubAttributionBehaviorSubject(): BehaviorSubject<AttributionType> {
    if (this.bsSubAttribution$ === undefined) {
      this.bsSubAttribution$ = new BehaviorSubject<AttributionType>(this.subAttributionTypeSave);
    }
    return this.bsSubAttribution$;
  }

  /**
   * Activates or deactivates the application of an attribution
   */
  public toggleAttribution(): boolean{
    this.attributionToggle = !this.attributionToggle;
    return this.attributionToggle;
  }

  public toggleSubAttribution(): boolean {
    this.subAttributionToggle = !this.subAttributionToggle;
    return this.subAttributionToggle;
  }

  /**
   * Triggers an update of the current attribution
   *
   * All observers who have subscribed to the behaviorSubject are informed afterwards
   */
  public updateAttribution(attributionType: AttributionType) {
    this.attributionTypeSave = attributionType;
    this.getAttributionBehaviorSubject().next(this.attributionTypeSave);
  }

  /**
   * Triggers an update of the current attribution
   *
   * All observers who have subscribed to the behaviorSubject are informed afterwards
   */
  public updateSubAttribution(attributionType: AttributionType) {
    this.subAttributionTypeSave = attributionType;
    this.getSubAttributionBehaviorSubject().next(this.subAttributionTypeSave);
  }
}
