import {Pipe, PipeTransform} from '@angular/core';
import {CardPattern} from '../../shared/card-pattern';
import {RecommendedGameDesign} from '../../design-recommender/shared/recommended-game-design';
import {GameDesignElement} from '../../design-recommender/shared/game-design-element';
import {GameDesign} from '../../design-recommender/shared/game-design';
import {DeckType} from '../../shared/card-deck';
import {RecommendedGameDesignRelationScore} from '../../design-recommender/shared/recommended-game-design-relation-score';
import {RecommendedGameDesignElementScore} from '../../design-recommender/shared/recommended-game-design-element-score';

@Pipe({
  name: 'deckSort',
  // pure false is needed because the pipe does not detect changes in gameDesignElements
  pure: false,
})
export class DeckSortPipe implements PipeTransform {

  private static getScoreMapForElementSorting(cardPatterns: CardPattern[], recommendedGameDesignScores: RecommendedGameDesignRelationScore[]): Map<number, number> {
    const scoreMap = new Map<number, number>();
    for (const relationScore of recommendedGameDesignScores) {
      if (!scoreMap.has(relationScore.recommendedGameDesignRelation.sourceId)) {
        scoreMap.set(relationScore.recommendedGameDesignRelation.sourceId, relationScore.recommendationScore);
      } else {
        if (scoreMap.get(relationScore.recommendedGameDesignRelation.sourceId) < relationScore.recommendationScore) {
          scoreMap.set(relationScore.recommendedGameDesignRelation.sourceId, relationScore.recommendationScore);
        }
      }
    }
    for (const cp of cardPatterns) {
      if (!scoreMap.has(cp.id)) {
        scoreMap.set(cp.id, 0);
      }
    }
    return scoreMap;
  }

  /**
   * Add the scores for each problem element instead of using only the maximum score
   * @param cardPatterns
   * @param recommendedGameDesignScores
   * @private
   */
  private static getScoreMapForProblemSorting(cardPatterns: CardPattern[], recommendedGameDesignScores: RecommendedGameDesignElementScore[]): Map<number, number> {
    const scoreMap = new Map<number, number>();
    for (const elementScore of recommendedGameDesignScores) {
      if (!scoreMap.has(elementScore.recommendedGameDesignElement.elementId)) {
        scoreMap.set(elementScore.recommendedGameDesignElement.elementId, elementScore.recommendationScore);
      } else {
        const newScore = elementScore.recommendationScore + scoreMap.get(elementScore.recommendedGameDesignElement.elementId);
        scoreMap.set(elementScore.recommendedGameDesignElement.elementId, newScore);
      }
    }
    for (const cp of cardPatterns) {
      if (!scoreMap.has(cp.id)) {
        scoreMap.set(cp.id, 0);
      }
    }
    return scoreMap;
  }

  private static getPartOfGameDesignNumber(cardPattern: CardPattern, gameDesignElements: GameDesignElement[]): number {
    for (const element of gameDesignElements) {
      if (element.id === cardPattern.id) {
        return cardPattern.cardNumber;
      }
    }
    return 9999 + cardPattern.cardNumber;
  }

  private static getPartOfProblemDesignNumber(cardPattern: CardPattern, gameDesignElements: GameDesignElement[]): number {
    for (const element of gameDesignElements) {
      if (element.id === cardPattern.id) {
        return cardPattern.cardNumber;
      }
    }
    return 9999 + cardPattern.cardNumber;
  }

  transform(
    cardPatterns: CardPattern[],
    gameDesign: GameDesign,
    cardDeckType: DeckType,
    recommendedGameDesign: RecommendedGameDesign,
    sort: boolean): CardPattern[] {

    let gameDesignElements: GameDesignElement[] = [];
    switch (cardDeckType) {
      case DeckType.pattern:
        gameDesignElements = gameDesign.gameDesignElements;
        break;
      case DeckType.problem:
      case DeckType.causes:
        gameDesignElements = gameDesign.gameDesignProblems;
        break;
    }

    if (recommendedGameDesign !== undefined && recommendedGameDesign !== null && sort) {
      let gameDesignRelationScores: RecommendedGameDesignRelationScore[] = [];
      let scoreMap = new Map<number, number>();
      switch (cardDeckType) {
        case DeckType.pattern:
          scoreMap = DeckSortPipe.getScoreMapForElementSorting(cardPatterns, recommendedGameDesign.recommendedGameDesignRelationScores);
          break;
        case DeckType.problem:
        case DeckType.causes:
          scoreMap = DeckSortPipe.getScoreMapForProblemSorting(cardPatterns, recommendedGameDesign.recommendedGameDesignProblemScores);
          break;
      }
      cardPatterns.sort((a, b) => (scoreMap.get(a.id) > scoreMap.get(b.id) ? -1 : ((scoreMap.get(b.id) > scoreMap.get(a.id)) ? 1 : 0)));
    } else {
      if (sort) {
        cardPatterns.sort((a, b) => (
          // tslint:disable-next-line:max-line-length
          DeckSortPipe.getPartOfGameDesignNumber(a, gameDesignElements) > DeckSortPipe.getPartOfGameDesignNumber(b, gameDesignElements) ? 1 :
            DeckSortPipe.getPartOfGameDesignNumber(b, gameDesignElements) > DeckSortPipe.getPartOfGameDesignNumber(a, gameDesignElements) ? -1 : 0));
      } else {
        cardPatterns.sort((a, b) => (a.cardNumber > b.cardNumber ? 1 : b.cardNumber > a.cardNumber ? -1 : 0));
      }
    }
    return cardPatterns;
  }
}
