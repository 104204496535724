<ng-container *ngIf="cardPatternObstacleConnection$ | async as obstacleConnection; else loading">
  <div class="card_discussion_frontside">
    <div class="card_discussion_header">
      <table class="card_discussion_header">
        <tr>
        <td class="card_discussion_header_number">{{ obstacleConnection.patternCardNumber }}</td>
        <td class="card_discussion_header_title">{{ obstacleConnection.patternName }}</td>
        <td class="card_discussion_header_image">
          <img [src]="obstacleConnection.patternImageUrl" alt="Icon">
        </td>
        </tr>
      </table>
    </div>
    <div class="card_discussion_summary">
      {{ obstacleConnection.patternSummary }}
    </div>
    <ng-container *ngFor="let obstacleItem of obstacleConnection.obstacleItems">
      <div class="card_discussion_obstacle_question">
        <div class="card_discussion_obstacle_pattern_switch"><em-relation-add [obstacleConnection]="obstacleConnection" [obstacleItem]="obstacleItem"></em-relation-add></div>
        {{ obstacleItem.question }}
      </div>
      <div class="card_discussion_obstacle_pattern">
        <div class="card_discussion_obstacle_pattern_number">{{ obstacleItem.cardNumber }}</div>
        <div class="card_discussion_obstacle_pattern_description">
          <span class="card_discussion_obstacle_pattern_description_title">{{ obstacleConnection.obstaclePatternName}}: </span>
          {{ obstacleItem.patternDescription }}
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-template #loading>
  <span style="color:#999999">Beschreibung wird geladen...</span>
</ng-template>
