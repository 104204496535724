import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'em-side-nav-content',
  templateUrl: './side-nav-content.component.html',
  styleUrls: ['./side-nav-content.component.css']
})
export class SideNavContentComponent implements OnInit {
  @Input() name;

  constructor() { }

  ngOnInit(): void {
  }

}
